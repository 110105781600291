import { Injectable } from '@angular/core';
import {ContractHttpResponse, TokensHttpResponse} from "../../shared/interfaces/zertiIndexer-api";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ZertiIndexerService {
  zertiIndexerApiUrl: string = environment.zertiIndexerApiUrl
  reference = `My Tree Initiative`

  constructor(
    private http: HttpClient
  ) { }

  getContracts(){
    const URL = `${this.zertiIndexerApiUrl}/contracts/reference`;
    const body = {
      reference: this.reference
    }
    return this.http.post<ContractHttpResponse>(URL, body);
  }

  getTreesNfts(chainId: string, contractAddress: string, walletAddress: string){
    const URL = `${this.zertiIndexerApiUrl}/tokens/${chainId}/${contractAddress}/${walletAddress}`;

    return this.http.get<TokensHttpResponse>(URL);
  }
}
