import {Component, OnDestroy} from '@angular/core';
import {EthersService} from "../../../services/ethers/ethers.service";
import {Subscription} from "rxjs";
import {Clipboard} from '@angular/cdk/clipboard';
import {NgbTooltip, NgbTooltipConfig} from "@ng-bootstrap/ng-bootstrap";
import {Router} from "@angular/router";

@Component({
  selector: 'app-mywallet',
  templateUrl: './mywallet.component.html',
  styleUrls: ['./mywallet.component.scss']
})
export class MywalletComponent implements OnDestroy {
  private subscriptions: Subscription[] = [];
  walletAddress: string = ''
  privateKey: string = ''
  isPasswordVisible: boolean = false;
  isMetamask = true;

  constructor(
    private etherService: EthersService,
    private clipboard: Clipboard,
    private tooltipConfig: NgbTooltipConfig,
    private router: Router,
  ) {
    tooltipConfig.triggers = 'click:blur'

    this.walletAddress = this.etherService.walletAddress
    this.subscriptions.push(
      this.etherService.$walletAddress.subscribe((walletAddress) => {
        if (!walletAddress)
          this.router.navigate(['/'])
        this.walletAddress = walletAddress
        setTimeout( () => {
          this.isMetamask = this.connectMethodIsMetamask()
          this.setPrivateKey()
        }, 100)
      })
    )

    if (!this.walletAddress)
      this.router.navigate(['/'])

    setTimeout( () => {
      this.isMetamask = this.connectMethodIsMetamask()
      this.setPrivateKey()
    }, 100)

  }


  togglePasswordVisibility() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  connectMethodIsMetamask(){
    if (localStorage.getItem('connectionInfo')){
      const connectionInfo = JSON.parse(localStorage.getItem('connectionInfo')!)

      return connectionInfo.connectMethod === 'METAMASK' || connectionInfo.connectMethod === 'WEBWALLET'
    }else{
      return false
    }
  }

  setPrivateKey(){
    if (!this.isMetamask && this.etherService.wallet)
      this.privateKey = this.etherService.wallet.privateKey
  }


  copyToClipboard(value: string, tooltip: NgbTooltip) {
    this.clipboard.copy(value);

    setTimeout(() => {
      tooltip.close();
    }, 1500);

  }


  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }


}
