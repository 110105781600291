import {Injectable} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {QrExporterModalComponent} from "../../shared/components/qr-exporter-modal/qr-exporter-modal.component";


@Injectable({
  providedIn: 'root'
})
export class QrExporterService {
  qrData!: string;

  constructor(
    private modalService: NgbModal,
  ) {
  }

  showQrExporter(qrData:string) {
    if (qrData) {
      this.qrData = qrData;
      this.modalService.open(QrExporterModalComponent, {size: 'lg'});
    }
  }
}
