import {IndexerContractData} from "../../shared/interfaces/zertiIndexer-api";

const defaultNetworks: IndexerContractData[] = [
  {
    id: 8,
    smart_contract: "0x37487CBa40947da12d6B316b5ac68d275177Ca5b",
    chain_id: "100",
    type: "ERC721",
    last_block_sync: "30724278",
    reference: "My Tree Initiative",
    active: 0,
    create_dt: "2023-07-03T08:42:49.000Z",
    update_dt: "2023-07-03T08:42:49.000Z",
    blockchains: {
      id: "100",
      chain: "gnosis",
      symbol: "XDAI",
      rpc: "https://xdai-rpc.gateway.pokt.network",
      contract_template: "https://gnosisscan.io/address/{contractAddress}",
      token_template: "https://gnosisscan.io/nft/{contractAddress}/{tokenId}",
      tx_template: "https://gnosisscan.io/tx/{tx}",
      create_dt: "2023-07-03T08:48:04.000Z",
      update_dt: "2023-07-03T08:48:04.000Z"
    }
  }
]


export default defaultNetworks;
