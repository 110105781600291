import {Component} from '@angular/core';
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ZertiAuthApiService} from "../../../services/zerti-auth/zerti-auth-api.service";
import {EthersService} from "../../../services/ethers/ethers.service";
import {SignupComponent} from "../signup/signup.component";
import {LoginComponent} from "../login/login.component";

@Component({
  selector: 'app-connect-modal',
  templateUrl: './connect-modal.component.html',
  styleUrls: ['./connect-modal.component.scss']
})
export class ConnectModalComponent {
  constructor(
    public activeModal: NgbActiveModal,
    public modalService: NgbModal,
    public zertiAuthServ: ZertiAuthApiService,
    public ethersService: EthersService
    ) {
  }

  open(modal: any) {
    this.modalService.dismissAll()
    this.modalService.open(modal)
  }
  loginWithGoogle(){
    window.location.href = this.zertiAuthServ.getCode('google');
  }
  loginWithTwitter(){
    window.location.href = this.zertiAuthServ.getCode('twitter');
  }

  loginWithGithub(){
    window.location.href = this.zertiAuthServ.getCode('github');
  }

  loginWithMetamask(){
    this.ethersService.metamaskConnect().then(() =>{
      this.modalService.dismissAll();
    });
  }
  loginWithWebWallet(){
    this.modalService.dismissAll();
    if (this.ethersService.getSavedKey()) {
      this.modalService.open(LoginComponent, {scrollable: true});
    } else {
      this.modalService.open(SignupComponent, {size: 'lg', scrollable: true});
    }
  }

}
