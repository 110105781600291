import {Injectable} from '@angular/core';
import {Observable, Subject, Subscriber} from "rxjs";
import {Forest} from "../../shared/interfaces/trees-interface";
import {HttpClient} from "@angular/common/http";
import {
  ForestApiInterface, ForestDataReduced,
  StripeTrees,
  StripeTreesFromSocket,
  StripeTreesFromSocketError
} from "../../shared/interfaces/forest-api-interface";
import { environment } from '../../../environments/environment';
import {TreeMetadata} from "../../shared/interfaces/metadatas";
import {UtilsService} from "../utils/utils.service";
import SocketIOClient from 'socket.io-client';
import {EthersService} from "../ethers/ethers.service";

@Injectable({
  providedIn: 'root'
})
export class ForestsService {

  $forests: Subject<Forest[]> = new Subject<Forest[]>();
  forests: Forest[] = []
  $selectedForest: Subject<Forest | null> = new Subject<Forest | null>();
  selectedForest!: Forest | null;
  zerticarbonApiUrl: string = environment.zerticarbonApiUrl

  constructor(
    private http: HttpClient,
    private utilsService: UtilsService,
    private ethersService: EthersService,
  ) {
    this.$forests.subscribe((forests: Forest[]) => {
      this.forests = forests
    })
    this.$selectedForest.subscribe((forest: Forest | null) => {
      this.selectedForest = forest
    })

    this.getForests().subscribe({
      next: (res) => {
        this.$forests.next(res)
      }
    })
  }

  getForestData(forestId: string): Observable<ForestApiInterface> {
    const URL = `${this.zerticarbonApiUrl}/areas/${forestId}`;

    return this.http.get<ForestApiInterface>(URL);
  }

  getForestDataReduced(forestId: number): Observable<ForestDataReduced> {
    const URL = `${this.zerticarbonApiUrl}/areas/${forestId}/planted`;

    return this.http.get<ForestDataReduced>(URL);
  }

  getForests() {
    const URL = `${this.zerticarbonApiUrl}/areas/planted`;
    // const URL = `https://api-dev.zerticarbon.com/areas/planted`;

    return this.http.get<Forest[]>(URL);
  }

  getTreeMetadata(id: string){
    const metadataUrl = `https://metadata.mytreeinitiative.com/${id}`
    const URL = `https://api-nft.zertifier.com/api/metadata?url=${metadataUrl}`;

    return this.http.get<TreeMetadata>(URL);
  }

  getMetadataCoordinates(treeMetadata: TreeMetadata){
    const coordinatesAttr = treeMetadata.attributes.find(attribute => attribute.trait_type == 'Coordinates')

    if (coordinatesAttr){
      const long = coordinatesAttr.value.split(',')[0]
      const longReducedDecimals = + this.utilsService.limitDecimals(Number(long), 7)
      const lat = coordinatesAttr.value.split(',')[1]
      const latReducedDecimals = + this.utilsService.limitDecimals(Number(lat), 7)

      coordinatesAttr.value = `${longReducedDecimals}, ${latReducedDecimals}`
      return coordinatesAttr.value
    }
    return ''
  }

  checkPayStatus(payCode: string){
    const URL = `${this.zerticarbonApiUrl}/trees/nfts/minted?stripeCheckInCode=${payCode}`;

    return this.http.get<StripeTrees[]>(URL);
  }

  checkPayStatusSocket(){
    const socket = SocketIOClient(`${this.zerticarbonApiUrl}/`)

    return new Observable<any>((observer: Subscriber<StripeTreesFromSocket[]>) => {
      socket.on('update:minted', (trees: StripeTreesFromSocket[]) => {
        // console.log(trees, "SOCKET")
        observer.next(trees)
      });
    })
  }

  checkPayStatusErrorSocket(){
    const socket = SocketIOClient(`${this.zerticarbonApiUrl}/`)

    return new Observable<any>((observer: Subscriber<StripeTreesFromSocketError>) => {
      socket.on('error:minted', (error: StripeTreesFromSocketError) => {
        observer.next(error)
      });
    })
  }


  transferTokens(fromWallet: string, toWallet: string, tokensIds: number[]){
    return new Promise((resolve, reject) => {
      this.getSignatureFromServer(fromWallet, toWallet, tokensIds).subscribe({
        next: async (resSignature) => {
          if (resSignature.status == 'success'){
            const signedMessage = await this.ethersService.signMessage(resSignature.signature)
            if (!signedMessage) reject('There is no connectionInfo on local storage')

            this.sendSignature(fromWallet, toWallet, tokensIds, signedMessage).subscribe({
              next: (resTransfer) => {
                resolve(resTransfer)
              },
              error: (errorTransfer) => {
                reject(errorTransfer)
              }
            })
          }
        },
        error: (errorSignature) => {
          console.log(errorSignature)
          reject(errorSignature)
        }
      })
    })

  }

  getSignatureFromServer(fromWallet: string, toWallet: string, tokensIds: number[]){
    const URL = `${this.zerticarbonApiUrl}/trees/nfts/transfer/signature`;
    const body = {
      fromWallet,
      toWallet,
      tokensIds
    }
    return this.http.post<{
      signature: string,
      status: string
    }>(URL, body);
  }

  sendSignature(fromWallet: string, toWallet: string, tokensIds: number[], messageSigned: string){
    const URL = `${this.zerticarbonApiUrl}/trees/nfts/transfer`;
    const body = {
      fromWallet,
      toWallet,
      tokensIds,
      signature: messageSigned
    }
    return this.http.post<{
      tx: string,
      status: string
    }>(URL, body);
  }

}
