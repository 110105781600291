import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }

  limitDecimals(number: number, maxDecimals: number): number {
    const multiplier = Math.pow(10, maxDecimals);
    return Math.round(number * multiplier) / multiplier;
  }
}
