import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from '../../../environments/environment';
import {DefaultPrice} from "../../shared/interfaces/stripe-interface";

@Injectable({
  providedIn: 'root'
})
export class StripeService {
  private baseUrl: string = environment.stripeApiUrl

  constructor(private http: HttpClient) {
  }

  getCheckoutUrl(quantity: string, walletAddress: string, forestId: number) {
    const url = `${this.baseUrl}/stripe?quantity=${quantity}&wallet=${walletAddress}&forest=${forestId}`

    window.location.href = url;
    // return this.http.get(url);
  }

  getOrderStatus(orderCode: string) {
    const url = `${this.baseUrl}/stripe/status?eventId=${orderCode}`

    return this.http.get(url);
  }

  getDefaultPrice(){
    const url = `${this.baseUrl}/stripe/default-price`

    return this.http.get<DefaultPrice>(url);
  }
}
