<div class="modal-header">
  <h4 class="modal-title text-body">Import QR</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div id="loadingMessage">
    Error on camera
  </div>
  <canvas id="qr-canvas" class="w-100"></canvas></div>
<div class="modal-footer justify-content-center">
  <button type="button" class="btn btn-dark" (click)="activeModal.close('Close click')">Close</button>
</div>
