<div class="modal-body container">
<!--  <img class="mb-3 d-block mx-auto custom-icon" src="assets/img/user-icon.png" height="100px" alt="">-->
  <div class="form-group mt-4 ">
    <label for="login-password" class="sr-only">Enter password</label>
    <input [(ngModel)]="password" (keyup.enter)="login()" [ngClass]="{'is-invalid': passwordErr}" id="login-password" class="form-control" type="password" [placeholder]="'Enter password'">
    <div *ngIf="passwordErr" class="invalid">
      Wrong password
    </div>
  </div>
  <div id="log-in-alert" class="mt-3"></div>
  <div class="row mx-1">
    <button (click)="login()" class="btn btn-lg btn-primary btn-block col-12 col-sm-3 mb-2 mb-sm-0" type="button">
      Login
    </button>
    <button class="btn btn-lg btn-secondary btn-block col-12 col-sm-6 mx-sm-2" (click)="clearWallet()" type="button">
      Use another wallet
    </button>
  </div>

</div>
<div class="modal-footer justify-content-center">
  <button type="button" class="btn btn-dark" (click)="activeModal.close('Close click')">Close</button>
</div>
