import {ApplicationRef, Component} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {loadStripe, Stripe} from "@stripe/stripe-js";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';


@Component({
  selector: 'app-stripe-modal',
  templateUrl: './stripe-modal.component.html',
  styleUrls: ['./stripe-modal.component.scss']
})
export class StripeModalComponent {
  stripe!: Stripe | null;
  stripeUrl: string = 'https://buy.stripe.com/test_eVaeWw1Kygez64MeUU'
  constructor(
    public activeModal: NgbActiveModal,
    private http: HttpClient,
    private appRef: ApplicationRef,
    private sanitizer: DomSanitizer
  ) {
    const unsafePageUrl = 'https://example.com'; // URL of the page you want to load
    this.stripeUrl = (this.sanitizer.bypassSecurityTrustResourceUrl(unsafePageUrl)).toString();
    // this.init()
  }

  async init() {
    console.log("load stripe")
    this.stripe = await loadStripe('pk_test_51NckrRFKjucGcUZZNfXtqCyE2nP3CpBg41JVdSNmS2FgXrDSljnDNEnsXAABf6GbwqKtWWubjesrzxzhCZjxaghO000NzIps3C');
    console.log(this.stripe)
    let verified =
      await this.stripe?.verifyIdentity('sk_test_51NckrRFKjucGcUZZwBENg7KMtukFn9i831hfsEacXbrGvfaV8Vmohwq2cC8eAizBNpDiusgkpORMTwgvft21Uet800WVq6aGfr')
    console.log(verified)
  }
}
