import { Component, OnInit } from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-qr-importer-modal',
  templateUrl: './qr-importer-modal.component.html',
  styleUrls: ['./qr-importer-modal.component.scss']
})
export class QrImporterModalComponent implements OnInit {

  constructor(
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
  }

}
