<div class="modal-header text-dark pb-2 px-3">
  <h4 class="modal-title mx-auto">Certificate widget</h4>
</div>
<div class="modal-body text-dark pt-2 px-3">
  <p>
    Copy and paste this code in your website to display the certificate.
  </p>

  <input [value]="createIFrameElement()" class="form-control text-center bg-white shadow-none cursor-pointer" readonly tabindex="-1">
</div>
<div class="modal-footer d-flex justify-content-between px-3">
  <button type="button" class="btn btn-secondary" (click)="copyToClipboard(IFrame, tooltipIFrame)"
          #tooltipIFrame="ngbTooltip"
          ngbTooltip="Copied!" tabindex="-1" triggers="manual">
    Copy
  </button>
  <button type="button" class="btn btn-dark" (click)="activeModal.close('Close click')">Close</button>
</div>
