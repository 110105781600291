<div *ngIf="selectedForest" class="container purchase-container">


    <div class="d-flex justify-content-center align-items-center mb-4">
      <div style="background-color: #489401"
           class="border border-2 border-primary border-white me-2 rounded-circle d-inline-block circle"></div>
      <span class="circle-text">Available</span>
      <div style=" background-color: #a86b00"
           class="border border-2 border-primary border-white ms-3 me-2 rounded-circle d-inline-block circle"></div>
      <span class="circle-text">Sold</span>
      <div style="background-color: #575757"
           class="border border-2 border-primary border-white ms-3 me-2 rounded-circle d-inline-block circle"></div>
      <span class="circle-text">Reserved</span>
    </div>

  <!--  <div class="row mx-auto mt-2">-->
  <div class="row mt-2 justify-content-center gx-5 w-100 mx-auto">
    <div class="col-12 col-lg-3 d-none d-lg-flex my-3 my-lg-0 h-100">
      <!--      <div class="h-100 rounded-4 border border-5 border-white carousel-div overflow-hidden">-->

      <div class="div-img-card rounded-4 border border-5 border-white overflow-hidden">

        <!--        <img src="{{selectedForest.image}}" class="card-img-top h-100 no-border-img" alt="...">-->
        <ngb-carousel
          [interval]="4000"
          [pauseOnHover]="true"
          [showNavigationArrows]="false"
          [showNavigationIndicators]="false"
        >
          <ng-template ngbSlide>
            <div class="position-relative">
              <div
                class="text-image-overlay position-absolute text-center bg-white text-dark rounded-end bottom-0 mb-4 start-0 shadow z-3">
                2022
              </div>
            <img class="rounded-4 purchase-fit-img-card" src="assets/img/tree-example-1.jpg"/>
            </div>
          </ng-template>
          <ng-template ngbSlide>
            <div class="position-relative">
              <div
                class="text-image-overlay position-absolute text-center bg-white text-dark rounded-end bottom-0 mb-4 start-0 shadow z-3">
                2023
              </div>
            <img class="rounded-4 purchase-fit-img-card" src="assets/img/tree-example-2.jpg"/>
            </div>
          </ng-template>
          <!--<ng-template ngbSlide>
            <div class="position-relative">
              <div
                class="text-image-overlay position-absolute text-center bg-white text-dark rounded-end bottom-0 mb-4 start-0 shadow z-3">
                2023
              </div>
            <img class="rounded-4 purchase-fit-img-card" src="assets/img/tree-example-3.jpg"/>
            </div>
          </ng-template>-->
        </ngb-carousel>
      </div>
    </div>
    <div class="col-12 col-lg-3 my-3 my-lg-0">
      <div class="rounded-4 border border-5 border-white w-100">
<!--        <img src="{{selectedForest.image}}" class="card-img-top h-100 no-border-img" alt="...">-->
        <app-map [forestId]="selectedForest.id"></app-map>
      </div>
    </div>
    <div class="col-10 col-lg-3 d-none d-lg-flex my-3 my-lg-0">
      <div class="div-img-card rounded-4 border border-5 border-white">
        <!--        <img src="assets/img/pine-example.png" class="purchase-fit-img-card" alt="...">-->
        <img src="assets/img/pine-example.png" class="purchase-fit-img-card" alt="...">
      </div>
    </div>
  </div>

  <p class="text-center mt-lg-4">
<!--    <span class="d-none d-lg-block">-->
    <span class="d-none">
      Use the slider to choose how many trees you would like to purchase.
      <br>
    </span>

    Each tree compensates 700 kg of CO2 during its life
  </p>
  <div class="row">
    <div class="col-10 col-md-7 mx-auto">
      <div class="range-wrapper text-center">
        <div class="position-relative d-inline-block w-100">
          <input class="w-100" type="range" id="tree-range" min="1" max="100" value="1" [(ngModel)]="qty"
                 (input)="updateTooltipPosition($event)">
          <div class="custom-tooltip text-white mb-2" [ngClass]="{'d-none': qty == undefined}"
               [style.left.px]="tooltipPosition">
            {{ qty }}
          </div>

          <ng-template #tooltipContent>{{ qty }}</ng-template>
          <div
            ngbTooltip="tooltipContent"
            [placement]="'top'"
            container="body"
            class="tooltip-inner"
          ></div>
        </div>

        <div class="d-none d-md-flex justify-content-between" id="lineNumbers">
          <div>1</div>
          <div>10</div>
          <div>20</div>
          <div>30</div>
          <div>40</div>
          <div>50</div>
          <div>60</div>
          <div>70</div>
          <div>80</div>
          <div>90</div>
          <div>100</div>
        </div>
        <div class="d-flex d-md-none justify-content-between" id="lineNumbers-mobile">
          <div>1</div>
          <div>20</div>
          <div>50</div>
          <div>70</div>
          <div>100</div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-md-0">

    <p class="text-center mt-2">
      {{qty}} {{parseInt(qty) > 1 ? 'Trees' : 'Tree'}}
      <span class="mx-2">|</span>
      Price {{treePrice*parseInt(qty)}} {{currency}}
    </p>
    <div class="col-10 col-lg-2 mx-auto">
      <button *ngIf="ethersService.loggedIn" class="btn btn-primary rounded-5 w-100" [disabled]="!ethersService.loggedIn || loading" (click)="loadStripe()">
<!--      <button class="btn btn-primary rounded-5 w-100 fs-4" [disabled]="loading" (click)="loadStripe()">-->
        <span>
          Purchase
        </span>
      </button>

      <button *ngIf="!ethersService.loggedIn"  class="btn btn-primary rounded-5 w-100" [disabled]="loading" (click)="loadLoginAlert()">
        <span (click)="loadLoginAlert()">
          Purchase
        </span>
      </button>
    </div>
  </div>
</div>

