<div class="modal-header border-0">
  <button type="button" class="btn-close shadow-none" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <i class="fa-solid fa-xmark fs-4 text-dark"></i>
  </button>
</div>
<div class="modal-body">
  <div class="row flex-column">

    <div class="col-12">
      <img class="w-100 rounded-4" src="{{tokens[0].image}}" >
    </div>

    <div class="col-12 my-2 mx-auto">
      <h4 class="text-center text-dark" readonly>
        {{tokenMessage}}
      </h4>
    </div>
    <div class="col-12 mb-3 mx-auto">
      <h4 class="text-center my-3 text-dark">Wallet Address</h4>
      <input [(ngModel)]="walletAddress" class="form-control text-center bg-white shadow-none">
    </div>
    <div class="col-12 mt-4">
      <div class="col-md-5 mx-auto text-center">
        <button *ngIf="!loading" class="btn btn-primary w-100" (click)="transfer()">
          Transfer
        </button>

        <button *ngIf="loading" class="btn btn-primary w-100" disabled>
          Transferring <i class="fa-solid fa-spinner fa-spin ms-2"></i>
        </button>
      </div>
    </div>
  </div>
</div>
