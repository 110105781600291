<div class="container text-center h-100 mt-5">
  <div class="d-flex flex-column ">
    <div class="mt-3">
      <h1>
        Error occurred while purchasing trees, please try it again.
      </h1>
    </div>
    <div class="mt-4">
      <a class="btn btn-primary shadow" href="/home">
        Go home
      </a>
    </div>
  </div>
</div>
