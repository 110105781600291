import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {HomeComponent} from "./views/pages/home/home.component";
import {PurchaseTreesComponent} from "./views/pages/purchase-trees/purchase-trees.component";
import {PurchaseSuccessComponent} from "./views/pages/purchase-success/purchase-success.component";
import {TotalTreesComponent} from "./views/pages/total-trees/total-trees.component";
import {MywalletComponent} from "./views/pages/mywallet/mywallet.component";
import {PurchaseErrorComponent} from "./views/pages/purchase-error/purchase-error.component";

const routes: Routes = [
  {
    path: "",
    component: HomeComponent
  },
  {
    path: "purchase/:forestId",
    component: PurchaseTreesComponent
  },
  {
    path: "purchase-successfully",
    component: PurchaseSuccessComponent
  },
  {
    path: "purchase-error",
    component: PurchaseErrorComponent
  },
  {
    path: "my-wallet",
    component: MywalletComponent
  },
  {
    path: "total-trees",
    component: TotalTreesComponent
  },
  {
    path: "total-trees/:walletAddress",
    component: TotalTreesComponent
  },
  {path: '**', redirectTo: '/', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
