import {Component} from '@angular/core';
import {ForestsService} from "../../../services/forests/forests.service";
import {TreeMetadata} from "../../../shared/interfaces/metadatas";
import {EthersService} from "../../../services/ethers/ethers.service";
import {ActivatedRoute} from "@angular/router";
import {
  StripeTrees,
  StripeTreesFromSocket,
  StripeTreesFromSocketError
} from "../../../shared/interfaces/forest-api-interface";
import {TransferModalComponent} from "../../partials/transfer-modal/transfer-modal.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-purchase-success',
  templateUrl: './purchase-success.component.html',
  styleUrls: ['./purchase-success.component.scss']
})
export class PurchaseSuccessComponent {
  purchasedTrees: string[] = []
  treesMetadatas: TreeMetadata[] = [];
  areMinted: boolean = false
  errorMint: boolean = false

  multipleTokens: boolean = false
  selectedTokensArray: number[] = [];


  constructor(
    public forestService: ForestsService,
    private ethersService: EthersService,
    private route: ActivatedRoute,
    public modalService: NgbModal,
  ) {
    // this.loadMetadatas()
    this.checkOrderStatus()
  }

  checkOrderStatus() {
    const sessionId = this.route.snapshot.queryParamMap.get('session_id');
    if (sessionId) {
      this.forestService.checkPayStatus(sessionId).subscribe({
        next: (mintedTrees: StripeTrees[]) => {
          if (mintedTrees.length) { //If trees are minted
            this.areMinted = true

            for (const tree of mintedTrees) {
              if (tree.nft)
                this.purchasedTrees.push(tree.nft.toString())
            }
            this.loadMetadatas()
          } else {//If not start sockets
            this.startPaymentSocket(sessionId)
            this.startErrorSocket(sessionId)
          }
        },
        error: (error) => {
          console.log(error)
        }
      })
    }
  }

  startPaymentSocket(sessionId: string){
    const checkPayStatus = this.forestService.checkPayStatusSocket().subscribe(
      (trees: StripeTreesFromSocket[]) => {
        for (const tree of trees) {
          if (tree.stripeCheckInCode == sessionId && tree.tokenId){
            this.purchasedTrees.push(tree.tokenId.toString())
          }
        }
        this.areMinted = true
        checkPayStatus.unsubscribe()
        this.loadMetadatas()
      });
  }

  startErrorSocket(sessionId: string){
    const checkPayError = this.forestService.checkPayStatusErrorSocket().subscribe(
      (err: StripeTreesFromSocketError) => {
        if (err.stripeCheckInCode == sessionId){
          this.errorMint = true
          checkPayError.unsubscribe()
        }
      }
    )
  }
  loadMetadatas() {
    for (const treeId of this.purchasedTrees) {
      this.forestService.getTreeMetadata(treeId).subscribe({
        next: (metadata: TreeMetadata) => {
          if (metadata)
            this.treesMetadatas.push(metadata)
          localStorage.removeItem('orderId');
        },
        error: (error) => {
          console.log(error)
        }
      })
    }
  }

  loadTokenPage(tokenId: number) {
    const baseUrl = `${this.ethersService.currentNetwork.blockchains.token_template}`
    let url = baseUrl.replace('{contractAddress}', this.ethersService.currentNetwork.smart_contract)
    url = url.replace('{tokenId}', tokenId.toString())

    window.open(url, '_blank');
  }

  selectToken(event: any, tokenId: number){
    const clickedElement = event.target;
    const parentElement = clickedElement.closest('.card');

    if (parentElement) {
      parentElement.classList.toggle('token-added');

      if (parentElement.classList.contains('token-added')) {
        this.selectedTokensArray.push(tokenId)
        this.multipleTokens = true;
      }
      else {
        const index = this.selectedTokensArray.indexOf(tokenId)
        if (index != -1)
          this.selectedTokensArray.splice(index, 1)
        if (this.selectedTokensArray.length == 0)
          this.multipleTokens = false
      }
    }
  }

  isTokenInArray(tokenId: number){
    return this.selectedTokensArray.includes(tokenId)
  }

  openTransferModal(tokenId?: number){
    if (tokenId) this.selectedTokensArray.push(tokenId)

    const modal = this.modalService.open(TransferModalComponent)
    const selectedTreesMetadatas = this.treesMetadatas.filter((treeMetadata) => {
      return this.selectedTokensArray.includes(treeMetadata.id)
    })
    modal.componentInstance.tokens = selectedTreesMetadatas
    modal.result.catch((reason: any) => {
      console.log(reason)
      if (reason == 'success'){
        for (const treeToken of selectedTreesMetadatas) {
          const indexToRemove = this.treesMetadatas.findIndex(tree => tree.id === treeToken.id);

          if (indexToRemove !== -1) {
            this.treesMetadatas.splice(indexToRemove, 1);
          }
        }

      }
      if (reason == 0){
        this.treesMetadatas = []
        this.loadMetadatas()
      }
      this.selectedTokensArray = []
      this.multipleTokens = false
      //this.loadMetadatas()
    })
  }
}
