import {Component} from '@angular/core';
import {EthersService} from "../../../services/ethers/ethers.service";
import {ZertiIndexerService} from "../../../services/zerti-indexer/zerti-indexer.service";
import {TreeMetadata} from "../../../shared/interfaces/metadatas";
import {IndexerToken} from "../../../shared/interfaces/zertiIndexer-api";
import {ForestsService} from "../../../services/forests/forests.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {TransferModalComponent} from "../../partials/transfer-modal/transfer-modal.component";
import {find} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {environment} from "../../../../environments/environment";
import Swal from "sweetalert2";
import {WidgetModalComponent} from "./modals/widget-modal/widget-modal.component";


@Component({
  selector: 'app-total-trees',
  templateUrl: './total-trees.component.html',
  styleUrls: ['./total-trees.component.scss']
})
export class TotalTreesComponent {
  walletAddressParam: string | null;
  walletAddress: string = ''
  loggedIn = false;
  treesMetadatas: TreeMetadata[] = [];
  loadingError = false;

  multipleTokens: boolean = false
  selectedTokensArray: number[] = [];

  refreshButtonDisabled = false;


  constructor(
    public ethersService: EthersService,
    private zertiOracleService: ZertiIndexerService,
    public forestsService: ForestsService,
    public modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.walletAddressParam = route.snapshot.paramMap.get('walletAddress');


    this.ethersService.$loggedIn.subscribe((loggedIn: boolean) => {
      this.loggedIn = loggedIn
    })


    this.ethersService.$walletAddress.subscribe((walletAddress: string) => {
      if (!walletAddress && !this.walletAddressParam) {
        this.router.navigate(['/'])
      } else if (this.walletAddressParam) {
        this.walletAddress = this.walletAddressParam
      } else if (walletAddress) {
        this.walletAddress = walletAddress
        this.loggedIn = true
      }
      if (!this.walletAddressParam) {
        this.getTotalNfts()
      }
    })

    /*this.ethersService.$currentNetwork.subscribe(() => {
      console.log("AAAA")
      if(this.walletAddress)
      this.getTotalNfts()
    })*/

    if (ethersService.walletAddress && ethersService.currentNetwork) {
      if (!this.walletAddressParam) {
        this.walletAddress = ethersService.walletAddress
        this.loggedIn = true
        this.getTotalNfts()
      }

    }

    if(this.walletAddressParam){
      this.getTotalNfts()
    }

    // if (!this.walletAddress)
    //   this.router.navigate(['/'])
  }


  getTotalNfts() {
    // const walletAddress = this.walletAddress
    /*this.zertiOracleService.getTreesNfts(
      this.ethersService.currentNetwork.chain_id,
      this.ethersService.currentNetwork.smart_contract,
      walletAddress
    )*/

    if (this.walletAddressParam || this.walletAddress) {
      this.zertiOracleService.getTreesNfts(
      environment.chainId,
      environment.contractAddress,
      this.walletAddressParam ||  this.walletAddress
    ).subscribe({
      next: (res) => {
        const tokens = res.data.tokens
        this.loadMetadatas(tokens)
        this.loadingError = false
      },
      error: (error) => {
        this.loadingError = true
      }
    })
    }

  }

  loadMetadatas(tokens: IndexerToken[]) {
    if (this.treesMetadatas)
      this.treesMetadatas = []

    for (const token of tokens) {
      this.forestsService.getTreeMetadata(token.token_id).subscribe({
        next: (metadata: TreeMetadata) => {
          if (metadata)
            this.treesMetadatas.push(metadata)
          this.loadingError = false
        },
        error: (error) => {
          console.log(error)
          this.treesMetadatas = []
          this.loadingError = true
          Swal.fire({
            title: "Server error",
            text: `Wait a few minutes to refresh`,
            icon: "error",
            confirmButtonColor: "#527636",
          });
        }
      })
    }
  }

  refresh(){
    this.selectedTokensArray = []
    this.treesMetadatas = []
    this.multipleTokens = false
    this.refreshButtonDisabled = true
    this.getTotalNfts()

    setTimeout(() => {
      this.refreshButtonDisabled = false;
    }, 1000);
  }

  loadTokenPage(tokenId: number) {
    const baseUrl = `${this.ethersService.currentNetwork.blockchains.token_template}`
    let url = baseUrl.replace('{contractAddress}', this.ethersService.currentNetwork.smart_contract)
    url = url.replace('{tokenId}', tokenId.toString())
    window.open(url, '_blank');
  }

  selectToken(event: any, tokenId: number) {
    const clickedElement = event.target;
    const parentElement = clickedElement.closest('.card');

    if (parentElement) {
      parentElement.classList.toggle('token-added');

      if (parentElement.classList.contains('token-added')) {
        this.selectedTokensArray.push(tokenId)
        this.multipleTokens = true;
      } else {
        const index = this.selectedTokensArray.indexOf(tokenId)
        if (index != -1)
          this.selectedTokensArray.splice(index, 1)
        if (this.selectedTokensArray.length == 0)
          this.multipleTokens = false
      }
    }
  }

  isTokenInArray(tokenId: number) {
    return this.selectedTokensArray.includes(tokenId)
  }

  openWidgetModal(){
    const modal = this.modalService.open(WidgetModalComponent)
    modal.componentInstance.walletAddress = this.walletAddress
  }
  openTransferModal(tokenId?: number) {
    console.log(tokenId, "SAKMFLSMA")
    if (tokenId) this.selectedTokensArray.push(tokenId)
    console.log(this.treesMetadatas, "METADATAS")

    const modal = this.modalService.open(TransferModalComponent)
    const selectedTreesMetadatas = this.treesMetadatas.filter((treeMetadata) => {
      return this.selectedTokensArray.includes(treeMetadata.id)
    })
    modal.componentInstance.tokens = selectedTreesMetadatas

    modal.result.catch((reason: any) => {
      console.log(reason)
      if (reason == 'success') {
        for (const treeToken of selectedTreesMetadatas) {
          const indexToRemove = this.treesMetadatas.findIndex(tree => tree.id === treeToken.id);

          if (indexToRemove !== -1) {
            this.treesMetadatas.splice(indexToRemove, 1);
          }
        }

      }
      if (reason == 0) {
        this.treesMetadatas = []
        this.getTotalNfts()
      }
      this.selectedTokensArray = []
      this.multipleTokens = false
      // this.getTotalNfts()
    })
  }
}
