<div class="modal-header">
  <h4 class="modal-title text-body text-center">Sign up</h4>
  <button type="button" class="header-close-btn" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body text-dark">
  <div *ngIf="step == 'newPassword'" id="set-password">
    <app-show-hide-input-text>
      <input [(ngModel)]="password" [ngClass]="{'is-invalid': passwordErr}" type="password"
             class="form-control" [placeholder]="'Password'">
    </app-show-hide-input-text>
    <app-show-hide-input-text>
      <input [(ngModel)]="repeatPassword" [ngClass]="{'is-invalid': repeatPasswordErr}"
             type="password" class="form-control"
             [placeholder]="'Repeat password'">
    </app-show-hide-input-text>

    <div *ngIf="passwordErrText" class="invalid mb-2 text-danger">{{passwordErrText}}</div>

    <p class="text-justify text-dark">
      <small>
        Remember the password!<br>
        This password will encrypt your wallet in a local file.<br>
        With this password you will be able to login decrypting your wallet.
      </small>
    </p>

  </div>
  <div *ngIf="step == 'walletInfo'">
    <app-wallet-info></app-wallet-info>
  </div>
</div>
<div class="modal-footer justify-content-center">
  <div *ngIf="step == 'newPassword'">
    <button (click)="createWallet();" type="button"
            class="btn btn-primary new-account-btn float-left">
      Crete new Account
    </button>
  </div>
  <div *ngIf="step == 'walletInfo'">
    <!--<button (click)="login();activeModal.close('Close click')" type="button" class="btn btn-primary">
      Login
    </button>-->
    <button (click)="activeModal.close('Close click')" type="button" class="btn btn-primary">
      Close
    </button>
  </div>
</div>

