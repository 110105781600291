<nav class="navbar navbar-expand-lg px-0 px-lg-3 py-3">
  <!--  <div class="d-flex flex-fill">-->
  <div class="row mx-auto ms-lg-5 col-12 col-lg-7 align-items-center">
    <div class="col-auto mx-auto px-md-0">
      <a [routerLink]="'/'">
        <img class="mw-100" src="assets/img/header-img.png" style="width: 160px">
      </a>
    </div>

    <div class="col d-none d-xl-block">
      <h1 class="header-title mb-0">MY TREE INITIATIVE</h1>
      <h4 class="header-subtitle">INDIVIDUAL TREE SPONSORSHIP MARKETPLACE</h4>
      <!--<h5 *ngIf="selectedForest" class="pt-3">
        {{selectedForest.name}} · 20 · {{selectedForest.treeTypes}} · 1 tn
      </h5>-->
    </div>
  </div>
  <div class="row w-100 mx-auto mt-3 align-self-md-start">
    <!--    <div *ngIf="isModalLoaded && loggedIn; else elseBlock" class="card-container me-4 ms-auto row align-self-start col-4">-->
    <div *ngIf="loggedIn; else elseBlock" class="card-container mx-auto me-lg-4 px-0 row justify-content-center">
      <!--<div ngbDropdown display="dynamic" class="col-5" id="chains-dropdown" placement="end">
        <button type="button" class="btn btn-secondary rounded-4 w-100 text-capitalize text-center" id="chainDropdown"
                ngbDropdownToggle>
          {{ethersService.currentNetwork ? ethersService.currentNetwork.blockchains.chain : ''}}
        </button>
        <div ngbDropdownMenu aria-labelledby="chainDropdown" class="dropdown-menu rounded-1">
          &lt;!&ndash;          <button ngbDropdownItem (click)="logout()" class="rounded-5">Log Out</button>&ndash;&gt;
          <button ngbDropdownItem *ngFor="let network of ethersService.networks"
                  (click)="changeNetwork(network.chain_id)" class="rounded-1 text-capitalize"
                  [ngClass]="{'bg-dark-subtle': ethersService.currentNetwork.chain_id == network.chain_id} ">
            {{network.blockchains.chain}}
          </button>
        </div>
      </div>-->
      <div class="col-5" id="chains-dropdown">
        <span class="btn btn-secondary bg-secondary rounded-4 w-100 text-capitalize text-center shadow-none" id="chainDropdown" style="cursor: context-menu">
          {{ethersService.currentNetwork ? ethersService.currentNetwork.blockchains.chain : ''}}
        </span>
      </div>


      <div ngbDropdown display="dynamic" class="col-6" id="user-dropdown">
        <button type="button" class="btn btn-secondary rounded-5 w-100" id="userDropdown" ngbDropdownToggle>
          {{walletAddress | walletShortener: 4}}
        </button>
        <div ngbDropdownMenu aria-labelledby="userDropdown" class="dropdown-menu rounded-1 ">
          <a ngbDropdownItem class="rounded-1" [routerLink]="'/my-wallet'"> My Wallet</a>
          <a ngbDropdownItem class="rounded-1" [routerLink]="'/total-trees'"> My Trees</a>
          <button ngbDropdownItem (click)="logout()" class="rounded-1">Log Out</button>
        </div>
      </div>
    </div>
    <ng-template #elseBlock>
      <div class="card-container mx-auto me-md-4 ms-md-auto col-5">
        <button class="btn btn-secondary rounded-5 w-100" (click)="login()">Log in</button>
      </div>
    </ng-template>
  </div>

</nav>
