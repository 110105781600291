<div class="modal-body pt-4">
  <div class="row g-3 mx-2">
    <button (click)="openNewWalletModal()" class="btn btn-lg btn-light btn-block">
      New account
    </button>
    <button (click)="openImportKeystoreModal()" class="btn btn-lg btn-light btn-block">
      Import Keystore
    </button>
    <button (click)="openImportPrivateKeyModal()" class="btn btn-lg btn-light btn-block">
      Import Private Key
    </button>
    <button (click)="openImportMnemonicModal()" class="btn btn-lg btn-light btn-block">
      Import Mnemonic Phrase
    </button>
  </div>
  <div class="my-3 w-100 text-center">

  <button type="button" class="btn btn-dark" (click)="activeModal.close('Close click')">Close</button>
  </div>

</div>


