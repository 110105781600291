<!--<div class="text-white bg-background">
  <div class="modal-header border-0">
    <button type="button" class="btn-close text-white shadow-none border-0" aria-label="Close"
            (click)="activeModal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <div class="w-100">
      <div class="custom-container mt-4 mx-auto">
        <div class="row g-3 d-flex justify-content-center">
          <div *ngIf="wood" class="col-md-5 mb-3 position-relative">
            <div class="p-2">
              <div class="div-img-card rounded-2">
                <img src="{{wood.image}}" class="card-img-top h-100 no-border-img" alt="...">
              </div>
            </div>
          </div>

          <div *ngIf="wood" class="col-md-6 mb-3 text-center ms-md-4">
            <h2 class="mb-0">{{wood.name}}</h2>
            <p class="mt-0 mb-0 small">Token id: {{wood.id}}</p>
            <hr class="mt-2 mb-3">

            <div class="mb-3 ">
              <h4>Details</h4>

              <div class="row g-3">
                <div class="col-6">
                  <div class="card h-100 border-0 bg-secondary text-white shadow">
                    <div class="card-body">
                      <h5>
                        Date
                      </h5>
                      <p class="mb-0 text-capitalize">{{wood.year | date:'mediumDate'}}</p>
                    </div>
                  </div>
                </div>

                <div class="col-6">
                  <div class="card h-100 border-0 bg-secondary text-white shadow">
                    <div class="card-body">
                      <h5>
                        Country
                      </h5>
                      <p class="mb-0 text-capitalize">{{wood.country}}</p>
                    </div>
                  </div>
                </div>

                <div class="col-6">
                  <div class="card h-100 border-0 bg-secondary text-white shadow">
                    <div class="card-body">
                      <h5>
                        Owner
                      </h5>
                      <p class="mb-0 text-capitalize">{{wood.owner}}</p>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="card h-100 border-0 bg-secondary text-white shadow">
                    <div class="card-body">
                      <h5>
                        Cadastre
                      </h5>
                      <p class="mb-0 text-capitalize">{{wood.cadastre}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-5">
              <div class="row">
                <div class="col-2">
                  <button class="btn btn-secondary rounded-5" (click)="btnSubtract()">-</button>
                </div>
                <div class="col-8">
                  <input class="mb-4 mb-md-3 mb-lg-4 w-100 text-center form-control shadow rounded-5" type="number" min="1"
                         placeholder="Quantity" [ngModel]="quantity" disabled>
                </div>
                <div class="col-2">
                  <button class="btn btn-secondary rounded-5" (click)="btnSum()">+</button>
                </div>
              </div>

              <button class="btn btn-primary shadow d-block w-100 rounded-5 text-white">
                Purchase
              </button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>-->
<div class="text-white">
  <div class="modal-header border-0">
    <button type="button" class="btn-close text-white shadow-none border-0" aria-label="Close"
            (click)="activeModal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <div class="w-100">
      <div class="custom-container mt-4 mx-auto">
        <div class="row g-3 d-flex justify-content-center">
          <div *ngIf="wood" class="col-md-5 mb-3 position-relative">
            <div class="p-2">
              <div class="div-img-card rounded-2">
                <img src="{{wood.image}}" class="card-img-top h-100 no-border-img" alt="...">
              </div>
            </div>
          </div>

          <div *ngIf="wood" class="col-md-6 mb-3 text-center ms-md-4">
            <h2 class="mb-0">{{wood.name}}</h2>
            <p class="mt-0 mb-0 small">Token id: {{wood.id}}</p>
            <hr class="mt-2 mb-3">

            <div class="mb-3 ">
              <h4>Details</h4>

              <div class="row g-3">
                <div class="col-6">
                  <div class="card h-100 border-0 bg-secondary text-white shadow">
                    <div class="card-body">
                      <h5>
                        Date
                      </h5>
                      <p class="mb-0 text-capitalize">{{wood.year | date:'mediumDate'}}</p>
                    </div>
                  </div>
                </div>

                <div class="col-6">
                  <div class="card h-100 border-0 bg-secondary text-white shadow">
                    <div class="card-body">
                      <h5>
                        Country
                      </h5>
                      <p class="mb-0 text-capitalize">{{wood.country}}</p>
                    </div>
                  </div>
                </div>

                <div class="col-6">
                  <div class="card h-100 border-0 bg-secondary text-white shadow">
                    <div class="card-body">
                      <h5>
                        Owner
                      </h5>
                      <p class="mb-0 text-capitalize">{{wood.owner}}</p>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="card h-100 border-0 bg-secondary text-white shadow">
                    <div class="card-body">
                      <h5>
                        Cadastre
                      </h5>
                      <p class="mb-0 text-capitalize">{{wood.cadastre}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-5">
              <div class="row">
                <div class="col-2">
                  <button class="btn btn-secondary rounded-5" (click)="btnSubtract()">-</button>
                </div>
                <div class="col-8">
                  <input class="mb-4 mb-md-3 mb-lg-4 w-100 text-center form-control shadow rounded-5" type="number" min="1"
                         placeholder="Quantity" [ngModel]="quantity" disabled>
                </div>
                <div class="col-2">
                  <button class="btn btn-secondary rounded-5" (click)="btnSum()">+</button>
                </div>
              </div>

              <button class="btn btn-primary shadow d-block w-100 rounded-5 text-white">
                Purchase
              </button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

