<div class="modal-header">
  <h4 class="modal-title text-body text-center">Import Private Key</h4>
  <button type="button" class="header-close-btn" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body text-dark">
  <app-show-hide-input-text [importQr]="true">
    <input [(ngModel)]="privateKey" type="password" class="form-control"
           [placeholder]="'Private key'">
  </app-show-hide-input-text>

  <app-show-hide-input-text>
    <input [(ngModel)]="password" [ngClass]="{'is-invalid': passwordErr}" type="password"
           class="form-control" [placeholder]="'Password'">
  </app-show-hide-input-text>
  <app-show-hide-input-text>
    <input [(ngModel)]="repeatPassword" [ngClass]="{'is-invalid': repeatPasswordErr}"
           type="password" class="form-control"
           [placeholder]="'Repeat password'">
  </app-show-hide-input-text>

  <div *ngIf="passwordErrText" class="invalid mb-2 text-danger">{{passwordErrText}}</div>
  <div *ngIf="privateKeyErr" class="invalid mb-2 text-danger">
    Wrong private key
  </div>
  <p class="text-justify">
    <small>
      Remember the password!<br>
      This password will encrypt your wallet in a local file.<br>
      With this password you will be able to login decrypting your wallet.`
    </small>
  </p>
</div>
<div class="modal-footer justify-content-center">
  <button (click)="login()" type="button" class="btn btn-primary float-left">
    Login
  </button>
  <!--<button (click)="activeModal.close('Close click')" type="button" class="btn btn-primary">
    Close
  </button>-->
</div>
