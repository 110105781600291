import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {SHA256} from 'crypto-js';
import {PrivateKeyHttpResponse} from "../../shared/interfaces/zertiauth-http";


@Injectable({
  providedIn: 'root'
})
export class ZertiAuthApiService {
  baseUrl: string = "https://auth.zertifier.com"
  // baseUrl: string = "http://192.168.3.108:3000"
  appId: string = "0ba3f4b3-55fa-499f-8782-23c81a2b4652" //CHANGE IT TO YOUR APP ID
  // appId: string = "b8d450fc-948d-4376-9484-6c203bfbbd47" //CHANGE IT TO YOUR APP ID
  redirectUrl: string = ''

  constructor(
    private http: HttpClient,
  ) {
    this.redirectUrl = window.location.origin;
  }


  getCode(platform: 'google' | 'twitter' | 'facebook' | 'linkedin' | 'github') {
    const baseCode = this.generateRandomString(32)
    localStorage.setItem('baseCodeChallenge', baseCode);

    const codeChallenge = SHA256(baseCode).toString();

    const url =
      `${this.baseUrl}/zauth/oauth/${platform}?app-id=${this.appId}&redirect-url=${this.redirectUrl}&code-challenge=${codeChallenge}&code-challenge-method=S256`

    return url
  }


  getPrivateKey(code: string) {
    const url = `${this.baseUrl}/zauth/web3/credentials/`
    let body;
    if (localStorage.getItem('pkBody')){
      body = JSON.parse(localStorage.getItem('pkBody')!)
    }else {
      body = {
        code,
        codeVerifier: localStorage.getItem('baseCodeChallenge')
      }
    }
    localStorage.setItem('pkBody', JSON.stringify(body));

    return this.http.post<PrivateKeyHttpResponse>(url, body)
  }

  generateRandomString(length: number): string {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const randomChars = Array.from({length}, () => characters.charAt(Math.floor(Math.random() * characters.length)));

    return randomChars.join('');
    ;
  }
}
