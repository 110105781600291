import {Injectable} from '@angular/core';
import {ethers} from 'ethers';
import {BehaviorSubject, Subject} from "rxjs";
import Swal from "sweetalert2";
import {EncryptionService} from "../utils/encryption.service";
import {ZertiIndexerService} from "../zerti-indexer/zerti-indexer.service";
import {IndexerContractData} from "../../shared/interfaces/zertiIndexer-api";
import {ZertiAuthApiService} from "../zerti-auth/zerti-auth-api.service";
import defaultNetworks from './default-networks';

declare let web3: any;
declare let window: any;

@Injectable({
  providedIn: 'root'
})
export class EthersService {

  $loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  loggedIn: boolean = false;

  wallet!: ethers.Wallet;
  walletAddress!: string;
  $wallet: Subject<ethers.Wallet | null> = new Subject<ethers.Wallet | null>()
  $walletAddress: Subject<string> = new Subject<string>()

  provider!: ethers.providers.JsonRpcProvider | ethers.providers.Web3Provider | ethers.providers.Provider;
  metamasksSigner!: ethers.Signer;

  networks!: IndexerContractData[];
  currentNetwork!: IndexerContractData;
  $currentNetwork: Subject<IndexerContractData> = new Subject<IndexerContractData>();

  connectMethod!: 'METAMASK' | 'WEBWALLET' | 'ZERTIAUTH' | '';

  public decodePass!: string;

  constructor(
    private encryptionService: EncryptionService,
    private zertiOracleService: ZertiIndexerService,
    private zertiAuthApiService: ZertiAuthApiService
  ) {
    this.$loggedIn.subscribe((loggedIn: boolean) => {
      this.loggedIn = loggedIn
    })

    this.$wallet.subscribe((wallet: ethers.Wallet | null) => {
      if (wallet){
        this.wallet = wallet
        this.$walletAddress.next(wallet.address)
      }
    })
    this.$walletAddress.subscribe((walletAddress: string) => {
      this.walletAddress = walletAddress
      if (walletAddress){
        this.$loggedIn.next(true)

        //Since this is triggered every page refresh, we need to get the connectmethod from local storage to not have it as undefined
        if (!this.connectMethod && localStorage.getItem('connectionInfo')) {
          const connectionInfo = JSON.parse(localStorage.getItem('connectionInfo')!)
          this.connectMethod = connectionInfo.connectMethod
        }
        const connectionInfo: any = {
          walletAddress: walletAddress,
          // chain: this.currentNetwork.chain_id
          chain: 100, //TODO: CHANGE,
          connectMethod: this.connectMethod
        }
        localStorage.setItem('connectionInfo', JSON.stringify(connectionInfo))
      }
      else
        this.$loggedIn.next(false)
    })

    this.$currentNetwork.subscribe((network: IndexerContractData) => {
      this.currentNetwork = network
    })

    this.getNetworksFromOracle()
    this.loadWalletFromLocalStorage()
  }


  createWalletFromPrivateKey(privateKey: string){
    const wallet = new ethers.Wallet(privateKey)
    this.$wallet.next(wallet)
    this.$walletAddress.next(wallet.address)
  }

  async metamaskConnect(): Promise<string>{
    return new Promise(async (resolve, reject) => {
      if (typeof window.ethereum !== 'undefined') {
        await window.ethereum.request({method: 'eth_requestAccounts'});
        const provider = new ethers.providers.Web3Provider(web3.currentProvider);

        this.metamasksSigner = provider.getSigner();
        this.connectMethod = 'METAMASK'
        this.$walletAddress.next(await this.metamasksSigner.getAddress())
        resolve('')
      }else{
        Swal.fire("ERROR", "Metamask not installed");
        reject('metamaskNotInstalled');
      }
    })
  }

  async signMessage(message: string){
    const connectionInfo = localStorage.getItem('connectionInfo')
    if (connectionInfo){
      const connectionInfoData = JSON.parse(connectionInfo)
      if (connectionInfoData.connectMethod == 'METAMASK'){
        // const messageSigned = await this.metamasksSigner.signMessage(message)
        const messageSigned = await this.metamasksSigner.signMessage(ethers.utils.arrayify(message))

        return messageSigned
      }else{
        return await this.wallet.signMessage(ethers.utils.arrayify(message))
      }
    }else return ''
  }

  getNetworksFromOracle(){
    this.zertiOracleService.getContracts().subscribe({
      next: (res) => {
        this.networks = res.data
        this.$currentNetwork.next(this.networks.find(network => network.chain_id === '100')!);
      },
      error: (error) => {
        console.log(error)
        this.networks = defaultNetworks
        this.$currentNetwork.next(this.networks.find(network => network.chain_id === '100')!);
      }
    })
  }

  changeNetwork(chainId: string){
    this.$currentNetwork.next(this.networks.find(network => network.chain_id === chainId)!);
  }

  async generateKeystore(password: string) {
    return await this.wallet.encrypt(password);
  }

  public async newWallet(password: string) {
    this.connectMethod = 'WEBWALLET'
    this.$wallet.next(ethers.Wallet.createRandom());
    this.saveKey(password);
    // this.webWalletConnect(this.webWallet);

    return this.wallet;
  }

  loadWalletFromLocalStorage(){
    if (localStorage.getItem('connectionInfo')){
      const connectionInfo = JSON.parse(localStorage.getItem('connectionInfo')!)
      // this.changeNetwork(connectionInfo.chain)

      if (localStorage.getItem('pkBody')){
        const pkBody = JSON.parse(localStorage.getItem('pkBody')!)

        this.zertiAuthApiService.getPrivateKey(pkBody.code).subscribe({
          next: (res) => {
            this.createWalletFromPrivateKey(res.data.privateKey)
          },
          error: (err) => {
            console.log(err, "error")
            localStorage.removeItem('pkBody')

          }
        })
      }else{
        if (connectionInfo.connectMethod === 'METAMASK'){
          window.ethereum
            .request({ method: 'eth_accounts' })
            .then((accounts: string[]) => {
              if (accounts.length > 0) {
                this.metamaskConnect()
              }
            })
            .catch((error: any) => {
              localStorage.removeItem('connectionInfo')
            });
        }else
          this.$walletAddress.next(connectionInfo.walletAddress)
      }
    }

  }

  importWalletFromMnemonic(mnemonic: string, password: string) {
    try {
      this.connectMethod = 'WEBWALLET'
      this.$wallet.next(ethers.Wallet.fromMnemonic(mnemonic))
      this.saveKey(password);
      return this.wallet;
    } catch (error) {
      return false;
    }
  }

  importWalletFromPrivateKey(privateKey: string, password: string) {
    try {
      if (!privateKey.startsWith('0x')) {
        privateKey = '0x' + privateKey;
      }
      this.connectMethod = 'WEBWALLET'
      this.$wallet.next(new ethers.Wallet(privateKey));
      this.saveKey(password);
      return this.wallet;
    } catch (error) {
      return false;
    }
  }

  importWalletFromStorage(password: string) {
    const privateKey = this.encryptionService.decrypt(localStorage.getItem('encryptedKey'), password);
    this.connectMethod = 'WEBWALLET'
    this.$wallet.next(new ethers.Wallet(privateKey))
    // this.webWalletConnect(this.webWallet);
  }

  async importKeystoreWallet(keystore: any, password: string) {
    try {
      this.connectMethod = 'WEBWALLET'
      this.$wallet.next(await ethers.Wallet.fromEncryptedJson(keystore, password));
      this.saveKey(password);
      return this.wallet;
    } catch (error) {
      return false;
    }
  }


  saveKey(password: string) {
    this.decodePass = password;
    localStorage.setItem('encryptedKey', String(this.encryptionService.encrypt(this.wallet.privateKey, password)));
  }

  getSavedKey() {
    return localStorage.getItem('encryptedKey');
  }

  clearKey() {
    localStorage.removeItem('encryptedKey');
  }

  logout(){
    this.$wallet.next(null)
    this.$walletAddress.next('')
    localStorage.removeItem('connectionInfo')
    localStorage.removeItem('pkBody')

  }

}
