import {Component, Input} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-buy-modal',
  templateUrl: './buy-modal.component.html',
  styleUrls: ['./buy-modal.component.scss']
})
export class BuyModalComponent {
  @Input() wood!: any;
  quantity: number = 1;
  constructor(public activeModal: NgbActiveModal, private datePipe: DatePipe) {

  }

  btnSubtract(){
    this.quantity = this.quantity - 1 < 0 ? 0 : this.quantity - 1
  }

  btnSum(){
    this.quantity = this.quantity + 1
  }

}
