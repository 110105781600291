import {Component, OnInit} from '@angular/core';
import {EthersService} from "../../../services/ethers/ethers.service";


@Component({
    selector: 'app-wallet-info',
    templateUrl: './wallet-info.component.html',
    styleUrls: ['./wallet-info.component.scss']
})
export class WalletInfoComponent implements OnInit {

    wallet!: any;
    contractInfo!: any;
    balance!: any;
    decodePassword!: string;

    constructor(
        public ethersService: EthersService
    ) {
    }

    ngOnInit() {
        this.ethersService.$wallet.subscribe(() => {
            /*this.wallet = {
                address: this.ethersService.walletAddress
            };*/
            this.wallet = this.ethersService.wallet

        });
        this.wallet = this.ethersService.wallet
        this.decodePassword = this.ethersService.decodePass;

    }
}
