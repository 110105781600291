<div class="bg-light">
  <div class="modal-header text-dark">
    <h4 class="modal-title">Privacy Policy</h4>
    <button type="button" class="btn-close shadow-none mx-4" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body text-dark px-4" style="height: 807px">
    <div class="modal-text m-2 mx-auto text-grey">

      <strong>Terms and conditions</strong>
      <p>These terms and conditions ("Agreement") set forth the general terms and conditions of your use of the zerticarbon.com website.</p>
      <p>("Website"), "ZCO2" mobile application ("Mobile Application") and any of their related products and services (collectively, "Services"). This Agreement is legally binding between you ("User", "you" or "your") and ZertiCarbon OY (doing business as "ZCO2", "we", "us" or "our"). If you are entering into this agreement on behalf of a business or other legal entity, you represent that you have the authority to bind such entity to this agreement, in which case the terms "User", "you" or "your" shall refer to such entity. If you do not have such authority, or if you do not agree with the terms of this agreement, you must not accept this agreement and may not access and use the Services. By accessing and using the Services, you acknowledge that you have read, understood, and agree to be bound by the terms of this Agreement.<p>
      <p>You acknowledge that this Agreement is a contract between you and ZCO2, even though it is electronic and is not physically signed by you, and it governs your use of the Services.</p>

      <strong>Accounts and membership</strong>
      <p>You must be at least 18 years of age to use the Services. By using the Services and by agreeing to this Agreement you warrant and represent that you are at least 18 years of age. If you create an account on the Services, you are responsible for maintaining the security of your account and you are fully responsible for all activities that occur under the account and any other actions taken in connection with it. We may, but have no obligation to, monitor and review new accounts before you may sign in and start using the Services. Providing false contact information of any kind may result in the termination of your account. You must immediately notify us of any unauthorized uses of your account or any other breaches of security. We will not be liable for any acts or omissions by you, including any damages of any kind incurred as a result of such acts or omissions. We may suspend, disable, or delete your account (or any part thereof) if we determine that you have violated any provision of this Agreement or that your conduct or content would tend to damage our reputation and goodwill. If we delete your account for the
        foregoing reasons, you may not re-register for our Services. We may
        block your email address and Internet protocol address to prevent further registration.</p>

      <strong>Billing and payments</strong>
      <p>You shall pay all fees or charges to your account in accordance with the fees, charges, and billing terms in effect at the time a fee or charge is due and payable. If, in our judgment, your purchase constitutes a high-risk transaction, we will require you to provide us with a copy of your valid government-issued photo identification, and possibly a copy of a recent bank statement for the credit or debit card used for the purchase. We reserve the right to change products and product pricing at any time. We also reserve the right to refuse any order you place with us. We may, in our sole discretion, limit or cancel quantities purchased per person, per household or per order. These restrictions may include orders placed by or under the same customer account, the same credit card, and/or orders that use the same billing and/or shipping address. In the event that we make a change to or
        cancel an order, we may attempt to notify you by contacting the e-mail and/or billing address/phone number provided at the time the order was made.</p>

    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
  </div>
</div>

