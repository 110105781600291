<div class="bg-light">
  <div class="modal-header text-dark">
    <h4 class="modal-title">Cookies Policy</h4>
    <button type="button" class="btn-close shadow-none mx-4" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body text-dark ">
    <div class="modal-text m-2 container">

      <p><strong>POLÍTICA DE COOKIES&nbsp;</strong></p>
      <p><span style="font-weight: 400;">El Sitio Web</span>
        <a href="www.hpe.travel"><strong>www.hpe.travel</strong></a> <span style="font-weight: 400;">(en adelante, el “</span><strong>Sitio
          Web</strong><span style="font-weight: 400;">”) es propiedad</span> <span style="font-weight: 400;">de</span><strong> HOSTELETOUR PURE EVENTS
          S.L. </strong><span style="font-weight: 400;">en adelante</span><strong> Responsable del
          Tratamiento</strong><strong>&nbsp;</strong></p>
      <p><strong>RESPONBLE DEL TRATAMIENTO: HOSTELETOUR PURE EVENTS S.L. B55351878 CALLE MIGDIA, 7
        17844
        CORNELLÀ DEL TERRI / </strong><span style="font-weight: 400;">Contacto:&nbsp; </span><a href="mailto:Info@hpe.travel"><strong>info@hpe.travel</strong></a></p>
      <p><strong>HOSTELETOUR PURE EVENTS S.L. </strong><span style="font-weight: 400;">le da la bienvenida y le invita a leer atentamente las </span><strong>Política
        de COOKIES</strong><span style="font-weight: 400;"> de este Sitio Web (en adelante </span><strong>Política
        de
        COOKIES</strong><span style="font-weight: 400;"> que describen los términos y condiciones que serán aplicables a su navegación por el mismo, de conformidad con lo establecido en la normativa española de aplicación. Dado que</span>
        <a href="www.hpe.travel"><strong>www.hpe.travel. </strong></a><strong>&nbsp; </strong><span style="font-weight: 400;">podría modificar en el futuro esta </span><strong>Política
          de COOKIES,</strong><span style="font-weight: 400;"> le recomendamos que las visite periódicamente para estar debidamente informado de los cambios realizados.</span>
      </p>
      <p><span style="font-weight: 400;">Con el ánimo de que el Sitio Web se ajuste a los criterios de </span><strong>transparencia,
        claridad y sencillez</strong> <span style="font-weight: 400;">recomienda al </span><strong>Usuario</strong><span style="font-weight: 400;"> que cualquier sugerencia, duda o consulta sobre las </span><strong>Política
        de Privacidad </strong><span style="font-weight: 400;">pueda solucionarla contactando con</span>
        <a href="www.hpe.travel"><strong>&nbsp;</strong></a><strong>&nbsp;</strong><a href="www.hpe.travel"><strong>www.hpe.travel. </strong></a><span style="font-weight: 400;">a través de la dirección de correo electrónico</span>
        <a href="mailto:info@hpe.travel"><strong>info@hpe.travel</strong></a></p>
      <p><span style="font-weight: 400;">La Política de cookies está en castellano y es la versión auténtica.</span>
      </p>
      <p><strong>Tipos y tipología de cookies empleadas en nuestra página web:</strong>
      </p>

      <ul>
        <li style="font-weight: 400;"><strong>Cookies de análisis de origen</strong><span style="font-weight: 400;">: utilizamos estas cookies para estimar la cantidad de visitantes únicos, para mejorar nuestros sitios web y los sitios web de nuestros comerciantes, y para detectar las palabras más buscadas en los motores de búsqueda que conducen a una página web. Estas cookies no se utilizan para apuntarle con marketing en línea. Usamos estas cookies para saber cómo funcionan nuestros sitios web y los sitios web de nuestros comerciantes y realizar mejoras relevantes para mejorar su experiencia de navegación.</span>
        </li>
      </ul>

      <ul>
        <li style="font-weight: 400;"><strong>Cookies de análisis de terceros</strong><span style="font-weight: 400;">: también utilizamos Google Analytics y otros proveedores de análisis de terceros enumerados a continuación para ayudar a medir cómo los usuarios interactúan con el contenido de nuestro sitio web. Estas cookies "recuerdan" lo que nuestros usuarios han hecho en páginas anteriores y cómo han interactuado con el sitio web. Para obtener más información sobre Google Analytics, visite la página de información de Google. Para obtener instrucciones sobre cómo darse de baja de Google Analytics, consulte a continuación.</span>
        </li>
      </ul>

      <p><strong>No operativas</strong></p>

      <ul>
        <li style="font-weight: 400;"><strong>Las cookies publicitarias:</strong><span style="font-weight: 400;"> Se utilizan en nuestro sitio web para personalizar el marketing para usted y sus intereses y brindarle un servicio más personalizado en el futuro. Estas cookies recuerdan que usted visitó nuestro sitio web y podemos compartir esta información con terceros, como los anunciantes. Aunque estas cookies pueden rastrear las visitas de su dispositivo a nuestro sitio web y otros sitios, en general no pueden identificarlo personalmente. Sin estas cookies, los anuncios que ve pueden ser menos relevantes e interesantes para usted. Lea más sobre cómo las empresas usan cookies para realizar publicidad dirigida o reorientada aquí. No establecemos cookies publicitarias a través de los escaparates de nuestros comerciantes, aunque los comerciantes pueden optar por hacerlo de forma independiente.</span>
        </li>
      </ul>

      <p><strong>&nbsp;&nbsp;&nbsp;&nbsp;No
        operativas</strong></p>

      <ul>
        <li style="font-weight: 400;"><strong>Las cookies sociales y de contenido</strong><span style="font-weight: 400;">: Se colocan en muchos complementos de redes sociales (por ejemplo, el botón "me gusta" de Facebook) y otras herramientas destinadas a proporcionar o mejorar el contenido de un sitio web (por ejemplo, servicios que permiten la reproducción de archivos de video, o que crean secciones de comentarios).&nbsp;Integramos estos módulos en nuestra plataforma para mejorar la experiencia de navegar e interactuar con nuestros sitios web.&nbsp;Tenga en cuenta que algunos de estos servicios de terceros colocan cookies que también se utilizan para cosas como publicidad conductual, análisis y / o investigación de mercado.</span>
        </li>
      </ul>
      <p><span style="font-weight: 400;">&nbsp;&nbsp;&nbsp;</span><strong>No
        operativas</strong></p>

      <p><strong>¿Cuánto tiempo permanecerán las cookies en mi computadora o
        dispositivo móvil?</strong></p>
      <p><span style="font-weight: 400;">El período de tiempo que una cookie permanece en su computadora o dispositivo móvil depende de si es una cookie "persistente" o de "sesión".&nbsp;Las cookies de sesión duran hasta que deja de navegar y las cookies persistentes duran hasta que caduquen o se eliminen.&nbsp;La mayoría de las cookies que utilizamos son persistentes y caducarán entre 30 minutos y dos años a partir de la fecha de descarga en su dispositivo.&nbsp;Consulte la sección a continuación sobre cómo controlar las cookies para obtener más información sobre cómo eliminarlas antes de que caduquen.</span>
      </p>
      <p><strong>¿Cómo controlar las cookies?</strong></p>
      <p><span style="font-weight: 400;">Puede controlar y administrar las cookies de varias maneras.&nbsp;Tenga en cuenta que eliminar o bloquear las cookies puede afectar negativamente su experiencia de usuario y es posible que algunas partes de nuestro sitio web ya no sean totalmente accesibles.</span>
      </p>
      <p><span style="font-weight: 400;">La mayoría de los navegadores aceptan automáticamente las cookies, pero puede elegir si acepta o no las cookies a través de los controles de su navegador, que a menudo se encuentran en el menú "Herramientas" o "Preferencias" de su navegador.&nbsp;Para obtener más información sobre cómo modificar la configuración de su navegador o cómo bloquear, administrar o filtrar las cookies, puede encontrarlas en el archivo de ayuda de su navegador o en sitios como:&nbsp;</span><a href="http://www.allaboutcookies.org/"><span style="font-weight: 400;">www.allaboutcookies.org</span></a><span style="font-weight: 400;">&nbsp;.</span></p>
      <p><span style="font-weight: 400;">Muchos de los servicios de publicidad de terceros y otros servicios de seguimiento mencionados anteriormente le ofrecen la oportunidad de optar por no participar en sus sistemas de seguimiento.&nbsp;Puede leer más sobre la información que recopilan y cómo darse de baja a través de los enlaces de política de privacidad mencionados anteriormente</span><span style="font-weight: 400;">.</span></p>
      <p><strong>¿Qué son las cookies?</strong></p>
      <p><span style="font-weight: 400;">Las cookies sirven para almacenar y recuperar información relativa a los usuarios que visitan una web. Se usan para hacer seguimiento de las páginas visitadas, así como para recordar ciertas acciones del usuario tales como preferencias de navegación. Estas preferencias son recordadas cuando un usuario visita de nuevo la página.</span>
      </p>

      <p><strong>¿Qué información guardan en la web?</strong></p>
      <ol>
        <li><span style="font-weight: 400;"> Información estadística del uso del site.</span>
        </li>
        <li><span style="font-weight: 400;"> Registro de actividad que ha llevado a cabo el visitante (páginas vistas, búsquedas, compras, etc.)</span>
        </li>
        <li><span style="font-weight: 400;"> Datos de conexión a redes sociales para los usuarios que acceden con su usuario de Facebook o Twitter.</span>
        </li>
      </ol>
      <p><strong>¿Qué pasa si bloqueo su uso?</strong></p>
      <p><span style="font-weight: 400;">En caso de bloquear su uso, es posible que algunos servicios del web no estén disponibles.</span>
      </p>
      <p><span style="font-weight: 400;">¿Cómo puedo bloquear las cookies en mi navegador?</span>
      </p>
      <p><span style="font-weight: 400;">En los siguientes enlaces encontrará información relativa en su navegador:</span>
      </p>
      <ol>
        <li><span style="font-weight: 400;"> Chrome</span></li>
        <li><span style="font-weight: 400;"> Firefox</span></li>
        <li><span style="font-weight: 400;"> Safari</span></li>
        <li><span style="font-weight: 400;"> Internet Explorer</span></li>
      </ol>

      <p><strong>¿QUÉ ES UNA COOKIE?</strong></p>
      <p><span style="font-weight: 400;">Una cookie es un archivo de texto inofensivo que se guarda en su navegador cuando visita una página web que los use, casi todas lo hacen. La utilidad de la galleta es que la web sea capaz de recordar su visita cuando vuelva a navegar por este sitio web. Las cookies se utilizan desde hace más de 20 años, cuando aparecieron los primeros navegadores para la World Wide Web.</span>
      </p>

      <p><strong>¿QUÉ NO ES UNA COOKIE?</strong></p>
      <p><span style="font-weight: 400;">No es un virus, ni un troyano, ni un gusano, ni spam, ni spyware, ni abre ventanas pop-up.</span>
      </p>

      <p><strong>¿QUÉ INFORMACIÓN PUEDE ALMACENAR UNA COOKIE?</strong></p>
      <p><span style="font-weight: 400;">Las cookies no suelen almacenar información sensible sobre usted, como tarjetas de crédito o datos bancarios, fotografías, su DNI o información personal, etc. Los datos que se guardan son de carácter técnico, preferencias personales, personalización de contenidos, etc. El servidor web no lo asocia a usted como persona sino en su navegador web. De hecho, si usted navega habitualmente con Internet Explorer y prueba de navegar por la misma web con Firefox o Chrome verá que la web no se da cuenta que usted es la misma persona que en realidad está asociando al navegador, no a la persona.</span>
      </p>

      <p><strong>¿QUE SON LAS COOKIES PROPIAS Y DE TERCEROS?</strong></p>
      <p><span style="font-weight: 400;">Las cookies propias son las generadas por la página que está visitando y las de terceros son las generadas por servicios o proveedores externos como Facebook, Twitter, Google, etc.</span>
      </p>

      <p><strong>¿QUÉ PASA SI SE DESACTIVAN LAS COOKIES?</strong></p>
      <p><span style="font-weight: 400;">Para que entienda las consecuencias que puede tener desactivar las cookies le mostramos algunos ejemplos:</span>
      </p>
      <ul>
        <li><span style="font-weight: 400;"> No podrá compartir contenidos de esta web en Facebook, Twitter o cualquier otra red social.</span>
        </li>
        <li><span style="font-weight: 400;"> El sitio web no podrá adaptar los contenidos a sus preferencias personales, como suele ocurrir en las tiendas en línea.</span>
        </li>
        <li><span style="font-weight: 400;"> No podrá acceder al área personal de esta web, como por ejemplo Mi cuenta, o Mi perfil o Mis pedidos.</span>
        </li>
        <li><span style="font-weight: 400;"> Tiendas en línea: Le será imposible realizar compras en línea, deberán ser telefónicas o visitando la tienda física si es que la tienen.</span>
        </li>
        <li><span style="font-weight: 400;"> No será posible personalizar sus preferencias geográficas como franja horaria, divisa o idioma.</span>
        </li>
        <li><span style="font-weight: 400;"> El sitio web no podrá realizar analíticas web sobre visitantes y tráfico en la web, esto dificultará que la web sea competitiva.</span>
        </li>
        <li><span style="font-weight: 400;"> No podrá escribir en el blog, no podrá subir imágenes, publicar comentarios, valorar o puntuar contenidos. La web tampoco podrá saber si usted es un humano o una aplicación automatizada que publica spam.</span>
        </li>
        <li><span style="font-weight: 400;"> No se podrá mostrar publicidad sectorizada, esto reducirá los ingresos publicitarios de la web.</span>
        </li>
        <li><span style="font-weight: 400;"> Todas las redes sociales hacen uso de cookies, si las desactiva, no podrá utilizar ninguna red social.</span>
        </li>
      </ul>

      <p><strong>¿SE PUEDEN ELIMINAR LAS COOKIES?</strong></p>
      <p><span style="font-weight: 400;">Sí. No sólo eliminar, también bloquear, de forma general o particular para un dominio específico. Para eliminar las cookies de un sitio web tiene que ir a la configuración de su navegador y allí podrá buscar las asociadas al dominio en cuestión y proceder a su eliminación.</span>
      </p>

      <p><strong>CONFIGURACIÓN DE COOKIES PARA LOS NAVEGADORES MÁS POPULARES</strong>
      </p>
      <p><span style="font-weight: 400;">A continuación, le indicamos cómo acceder a una cookie determinada del navegador Chrome. Nota: estos pasos pueden variar en función en el navegador y su versión:</span>
      </p>

      <ol>
        <li><span style="font-weight: 400;"> Vaya a Configuración o Preferencias mediante el menú Archivo o bien pulsando el icono de personalización que aparece arriba a la derecha.</span>
        </li>
        <li><span style="font-weight: 400;"> Verá diferentes secciones, elija la opción Mostrar opciones avanzadas.</span>
        </li>
        <li><span style="font-weight: 400;"> Vaya a Privacidad, Configuración de contenido.</span>
        </li>
        <li><span style="font-weight: 400;"> Seleccione Todas las cookies y los datos de lugares.</span>
        </li>
        <li><span style="font-weight: 400;"> Aparecerá un listado con todas las cookies ordenadas por dominio. Para que le sea más fácil encontrar las Cookies de un determinado dominio introduzca parcial o totalmente la dirección en el campo Buscar cookies.</span>
        </li>
        <li><span style="font-weight: 400;"> Una vez realizado este filtro aparecerán en pantalla una o varias líneas con las Cookies de la web solicitada. Ahora sólo tiene que seleccionarla y pulsar la X para proceder a su Eliminación</span>
        </li>
      </ol>

      <p><span style="font-weight: 400;">Para acceder a la configuración de cookies del navegador Internet Explorer hace lo siguiente (pueden variar en función de la versión del navegador):</span>
      </p>

      <ol>
        <li><span style="font-weight: 400;"> Ir a Herramientas, Opciones de Internet</span></li>
        <li><span style="font-weight: 400;"> Haga clic en Privacidad.</span></li>
        <li><span style="font-weight: 400;"> Mueve el desplegable hasta ajustar el nivel de privacidad que desee.</span>
        </li>
      </ol>

      <p><span style="font-weight: 400;">Para acceder a la configuración de cookies del navegador Firefox hace lo siguiente (pueden variar en función de la versión del navegador):</span>
      </p>

      <ol>
        <li><span style="font-weight: 400;"> Vaya a Opciones o Preferencias según su sistema operativo.</span>
        </li>
        <li><span style="font-weight: 400;"> Haga clic en Privacidad.</span></li>
        <li><span style="font-weight: 400;"> En Histórico elige Usar una configuración personalizada para el historial.</span>
        </li>
        <li><span style="font-weight: 400;"> Ahora verá la opción Aceptar cookies, puede activarla o desactivarla según sus preferencias.</span>
        </li>
      </ol>

      <p><span style="font-weight: 400;">Para acceder a la configuración de cookies del navegador Safari para OSX hace lo siguiente (pueden variar en función de la versión del navegador):</span>
      </p>

      <ol>
        <li><span style="font-weight: 400;"> Vaya a Preferencias, después Privacidad.</span>
        </li>
        <li><span style="font-weight: 400;"> En este lugar verá la opción Bloquear Cookies para que ajuste el tipo de bloqueo que desee realizar.</span>
        </li>
      </ol>

      <p><span style="font-weight: 400;">Para acceder a la configuración de cookies del navegador Safari para iOS hace lo siguiente (pueden variar en función de la versión del navegador):</span>
      </p>

      <ol>
        <li><span style="font-weight: 400;"> Vaya a Configuración, luego Safari.</span></li>
        <li><span style="font-weight: 400;"> Vaya a Privacidad y Seguridad, verá la opción Bloquear Cookies para que ajuste el tipo de bloqueo que desee realizar.</span>
        </li>
      </ol>

      <p><span style="font-weight: 400;">Para acceder a la configuración de cookies del navegador para dispositivos Android hace lo siguiente (pueden variar en función de la versión del navegador):</span>
      </p>

      <ol>
        <li><span style="font-weight: 400;"> Ejecutar el navegador y pulse la tecla Menú, luego Configuración.</span>
        </li>
        <li><span style="font-weight: 400;"> Vaya a Confidencialidad, verá la opción Aceptar cookies para que active o desactive la casilla.</span>
        </li>
      </ol>

      <p><span style="font-weight: 400;">Para acceder a la configuración de cookies del navegador para dispositivos Windows Phone hace lo siguiente (pueden variar en función de la versión del navegador):</span>
      </p>

      <ol>
        <li><span style="font-weight: 400;"> Abra Internet Explorer, entonces Más, entonces Configuración</span>
        </li>
        <li><span style="font-weight: 400;"> Ahora puede activar o desactivar la casilla permitir cookies.</span>
        </li>
      </ol>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
  </div>
</div>

