import {Component, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {EthersService} from "../../../../services/ethers/ethers.service";
declare var $: any;

@Component({
  selector: 'app-import-mnemonic',
  templateUrl: './import-mnemonic.component.html',
  styleUrls: ['./import-mnemonic.component.scss']
})
export class ImportMnemonicComponent implements OnInit {
  mnemonic!: string;
  mnemonicErr!: boolean;

  password!: string;
  repeatPassword!: string;

  passwordErr!: boolean;
  repeatPasswordErr!: boolean;
  passwordErrText!: string;

  @ViewChild(ImportMnemonicComponent)
  public importMnemonic!: ImportMnemonicComponent;

  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private ethersService: EthersService
  ) {
  }

  ngOnInit() {
    this.mnemonic = '';
    this.mnemonicErr = false;
  }

  login() {
    this.mnemonicErr = false;
    const wallet = this.ethersService.importWalletFromMnemonic(this.mnemonic, this.password);
    if (wallet) {
      this.activeModal.close();
    } else {
      this.mnemonicErr = true;
    }
  }
}
