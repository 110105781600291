import {Component, OnDestroy} from '@angular/core';
import {interval, Subscription} from "rxjs";
import {ForestsService} from "../../../services/forests/forests.service";
import {Forest} from "../../../shared/interfaces/trees-interface";
import {Router} from "@angular/router";
import {StripeService} from "../../../services/stripe/stripe.service";
import Swal from 'sweetalert2'
import {EthersService} from "../../../services/ethers/ethers.service";

@Component({
  selector: 'app-purchase-trees',
  templateUrl: './purchase-trees.component.html',
  styleUrls: ['./purchase-trees.component.scss']
})
export class PurchaseTreesComponent implements OnDestroy {

  private subscriptions: Subscription = new Subscription();
  public forests: Forest[] = []
  public selectedForest!: Forest | undefined
  public qty: string = '1';
  tooltipPosition: number = 0;
  public loading = false;
  public treePrice = 0;
  public currency: string = '';

  constructor(
    private forestService: ForestsService,
    private router: Router,
    private stripeService: StripeService,
    public ethersService: EthersService
  ) {
    this.forests = this.forestService.forests
    this.subscriptions.add(this.forestService.$forests.subscribe((forests: Forest[]) => {
      this.forests = forests
    }))

    const HREF_ARRAY = this.router.url.split('/'); //get forest ID
    if (parseInt(HREF_ARRAY[2])) {
      this.getTreePrice()
      const forestId = parseInt(HREF_ARRAY[2])

      this.selectedForest = this.forests.find((forest: Forest) => forest.id == forestId)
      if (this.selectedForest)
        this.forestService.$selectedForest.next(this.selectedForest)
      else this.router.navigate(['/'])


    } else this.router.navigate(['/'])
  }

  updateTooltipPosition(event: any) {
    const range = event.target;
    const value = parseInt(range.value, 10);
    const min = parseInt(range.min, 10);
    const max = parseInt(range.max, 10);

    const newPosition = ((value - min) / (max - min)) * range.offsetWidth;
    this.tooltipPosition = newPosition;
  }

  loadStripe() {
    this.loading = true;
    // this.modalService.open(StripeModalComponent, {fullscreen: true});
    this.forestService.getForestDataReduced(this.selectedForest!.id).subscribe({
      next: (forest) => {
        const totalTrees = parseInt(forest.qty) - forest.used

        if (totalTrees >= parseInt(this.qty)){
          const walletAddress = this.ethersService.walletAddress

          this.stripeService.getCheckoutUrl(this.qty, walletAddress, this.selectedForest!.id)
        }else{
          Swal.fire({
            title: "Purchase quantity exceeded",
            text: `You can only purchase a maximum of ${totalTrees} trees`,
            icon: "error",
            confirmButtonColor: "#527636",
          });
          this.loading = false;
        }
      }
    })
  }

  getTreePrice(){
    this.stripeService.getDefaultPrice().subscribe({
      next: (defaultPrice) => {
        this.treePrice = defaultPrice.data.price
        this.currency = defaultPrice.data.currency == 'eur' ? '€' : '$'
      },
      error: (err) => {
        console.log(err)
      }
    })
  }

  loadLoginAlert(){
    Swal.fire({
      title: "Please login before purchasing",
      icon: "error",
      confirmButtonColor: "#527636",
    });
  }

  intervalGetOrderStatus(orderCode: string) {
    const orderInterval: any = interval(3000) // Poll every 3 seconds (adjust as needed)
      .subscribe(() => {
        this.stripeService.getOrderStatus(orderCode).subscribe((response: any) => {
          console.log(response)
          if (response.data == 'accepted') {
            Swal.hideLoading()
            Swal.update({
              title: 'Payment successfully processed',
              icon: 'success',
              showCloseButton: true,
              allowOutsideClick: true,
              allowEscapeKey: true,
            })
            orderInterval.unsubscribe()
          }
          if (response.data == 'denied') {
            Swal.hideLoading()
            Swal.update({
              title: 'Payment denied',
              icon: 'error',
              showCloseButton: true,
              allowOutsideClick: true,
              allowEscapeKey: true,
            })
            orderInterval.unsubscribe()
          }
        });
      });
  }

  ngOnDestroy(): void {
    this.forestService.$selectedForest.next(null)
    this.subscriptions.unsubscribe();
    localStorage.removeItem('orderId');
  }

  protected readonly parseInt = parseInt;
}
