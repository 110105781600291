import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import * as isoCountries from 'i18n-iso-countries';
import {ForestsService} from "../../../services/forests/forests.service";
import {
  DataTreeType,
  ForestApiInterface,
  ForestDataReduced,
  PerimeterCoordinate, ReducedTree,
  Tree
} from "../../interfaces/forest-api-interface";
import {HttpErrorResponse} from "@angular/common/http";
import {Coordinate} from "mapbox-gl";
import {animate} from "@angular/animations";

const that = this;

interface AllAreaTreesCoordinates{
  long: string;
  lat: string;
}

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements AfterViewInit {
  @Input() countries!: string[]
  @Input() forestId!: string | number;
  @Output() selectedCountry: EventEmitter<string> = new EventEmitter<string>();
  private map!: mapboxgl.Map;
  public forest!: ForestDataReduced;

  constructor(private forestsService: ForestsService) {
  }

  ngAfterViewInit() {
    isoCountries.registerLocale(require("i18n-iso-countries/langs/en.json"));
    // @ts-ignore
    mapboxgl.accessToken = 'pk.eyJ1IjoiemVydGlhbGV4IiwiYSI6ImNsbDNlNGQ2cTAzdXMzbG96MTY0YW5va2EifQ.RdvxEEcjcnjcKpOH-YMUnw';

    this.map = new mapboxgl.Map({
      container: 'map',
      // style: 'mapbox://styles/mapbox/light-v10',
      style: 'mapbox://styles/josedvd123/ckyubv4uz001v14lcg26hetvw',
      center: [5, 46],
      zoom: 14.4,
      attributionControl: false,
    });

    this.map.on('load', () =>{
      if (this.forestId) {
        const forestId = parseInt(this.forestId.toString())
        this.forestsService.getForestDataReduced(forestId).subscribe({
          next: (res) => {
            this.forest = res
            const trees = this.forest['trees'];
            const centerPoint: [number, number] = [parseFloat(this.forest['lng']), parseFloat(this.forest['lat']) ];

            const [minLng, minLat, maxLng, maxLat] = this.calculateBoundingBox(trees);
            this.setTrees(trees)
            this.map.setCenter(centerPoint)
            this.map.fitBounds([[minLng, minLat], [maxLng, maxLat]], {padding: 20});
          },
          error: (error: HttpErrorResponse) => {
            console.log(error)
          }
        })
      }
    })



  }

  setTrees(trees: ReducedTree[]) {

    let features: any = [];
    let soldFeatures: any = [];
    let guaranteeFeatures: any = [];

    trees.filter((tree) => {
      const treeObject = {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [tree.lng, tree.lat]
        }
      }

      if (tree.status == 'AVAILABLE'){
        features.push(treeObject)
      }
      if (tree.status == 'SOLD'){
        soldFeatures.push(treeObject)
      }
      if (tree.status == 'GUARANTEE'){
        guaranteeFeatures.push(treeObject)
      }
    })

    this.map.addSource('forests-source/' + this.forest.name + '-AVAILABLE', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features
      }
    });
    this.setLayer(this.forest.name + '-AVAILABLE',  '#489401')

    if (soldFeatures){
      this.map.addSource('forests-source/' + this.forest.name + '-SOLD', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: soldFeatures
        }
      });
      this.setLayer(this.forest.name + '-SOLD', '#a86b00')
    }

    if (guaranteeFeatures) {
      this.map.addSource('forests-source/' + this.forest.name + '-GUARANTEE', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: guaranteeFeatures
        }
      });
      this.setLayer(this.forest.name + '-GUARANTEE', '#575757')
    }

  }

  setLayer(reference: string, color: string){
    this.map.addLayer({
      id: 'forests/' + reference,
      source: 'forests-source/' + reference,
      type: 'circle',
      paint: {
        'circle-color': color,
        'circle-radius': 2
      }
    });
  }

  calculateZoom(minLng: number, minLat: number, maxLng: number, maxLat: number) {
    const WORLD_DIM = {height: 500, width: 460};
    const ZOOM_MAX = 30;

    function latRad(lat: number) {
      const sin = Math.sin(lat * Math.PI / 180);
      const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
      return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
    }

    function zoom(mapPx: number, worldPx: number, fraction: number) {
      return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
    }

    const latFraction = (latRad(maxLat) - latRad(minLat)) / Math.PI;

    const lngDiff = maxLng - minLng;
    const lngFraction = ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360;

    const latZoom = zoom(WORLD_DIM.height, WORLD_DIM.height, latFraction);
    const lngZoom = zoom(WORLD_DIM.width, WORLD_DIM.width, lngFraction);

    return Math.min(latZoom, lngZoom, ZOOM_MAX);
  }

  // Función para calcular la extensión del mapa basada en las coordenadas
  calculateBoundingBox(trees: ReducedTree[]) {
    let minLng = Infinity;
    let minLat = Infinity;
    let maxLng = -Infinity;
    let maxLat = -Infinity;

    let totalTreesCoordinates: any = trees.map((tree) => {
      return [parseFloat(tree.lng), parseFloat(tree.lat)]

    })

    for ( const coodinate of totalTreesCoordinates) {
      minLng = Math.min(minLng, coodinate[0]);
      minLat = Math.min(minLat, coodinate[1]);
      maxLng = Math.max(maxLng, coodinate[0]);
      maxLat = Math.max(maxLat, coodinate[1]);
    }

    return [minLng, minLat, maxLng, maxLat];
  }

  getCenterPoint(coordinates: AllAreaTreesCoordinates[]): any {
    let totalLng = 0;
    let totalLat = 0;
    for (const coordinate of coordinates) {
      totalLng += parseFloat(coordinate.long);
      totalLat += parseFloat(coordinate.lat);
    }
    return [
      totalLng / coordinates.length,
      totalLat / coordinates.length,
    ]
  }

/*  setLayers() {
    for (const country of this.countries) {
      this.map.addLayer(
        {
          id: `country-boundaries-${country}`,
          source: {
            type: 'vector',
            url: 'mapbox://mapbox.country-boundaries-v1',
          },
          'source-layer': 'country_boundaries',
          type: 'fill',
          paint: {
            'fill-color': '#44a141',
            'fill-opacity': 0.4,
          },
        },
        'country-label'
      );
      const countryCode = isoCountries.getAlpha3Code(country, 'en')

      this.map.setFilter(`country-boundaries-${country}`, [
        "in",
        "iso_3166_1_alpha_3",
        countryCode
      ]);

      this.setListeners(country)
    }
  }*/

  setListeners(country: string) {
    this.map.on('click', `country-boundaries-${country}`, (event: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
      this.selectedCountry.emit(country)
    })

    this.map.on('mouseenter', `country-boundaries-${country}`, (event: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
      this.map.getCanvas().style.cursor = 'pointer';
    })

    this.map.on('mouseleave', `country-boundaries-${country}`, (event: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
      this.map.getCanvas().style.cursor = '';
    })
  }
}
