import {AfterViewInit, Component, inject, Input} from '@angular/core';
import {TreeMetadata} from "../../../shared/interfaces/metadatas";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {ForestsService} from "../../../services/forests/forests.service";
import {EthersService} from "../../../services/ethers/ethers.service";
import Swal from "sweetalert2";

@Component({
  selector: 'app-transfer-modal',
  templateUrl: './transfer-modal.component.html',
  styleUrls: ['./transfer-modal.component.scss']
})
export class TransferModalComponent implements AfterViewInit {


  activeModal = inject(NgbActiveModal);
  tokenMessage: string = ""
  walletAddress = '';
  @Input() tokens: TreeMetadata[] = [];
  loading = false;

  constructor(
    public forestsService: ForestsService,
    public ethersService: EthersService
  ) {
  }

  ngAfterViewInit(): void {
    console.log(this.tokens.length)
    if (this.tokens.length > 1)
      this.tokenMessage = `${this.tokens.length} tokens`
    else
      this.tokenMessage = `Token ${this.tokens[0].id}`
  }

  async transfer() {
    this.loading = true
    const tokens = []
    for (const token of this.tokens) {
      tokens.push(token.id)
    }
    this.forestsService.transferTokens(this.ethersService.walletAddress, this.walletAddress, tokens)
      .then((res) => {
        console.log(res)
        this.loading = false

        Swal.fire({
          title: "Transfer successfully done",
          icon: "success",
          confirmButtonColor: "#527636",
        }).finally(() => {
          this.activeModal.dismiss('success')
        });
      }).catch((error) => {
        console.log(error)
        this.loading = false
        Swal.fire({
          title: "Transfer could not be done",
          text: `Try again in a few minutes`,
          icon: "error",
          confirmButtonColor: "#527636",
        }).finally(() => {
          this.activeModal.dismiss('error')
        });
      }
    )
  }
}
