<ng-container *ngIf="walletAddressParam">
  <h4 class="mx-auto text-center mb-3">
    <span [ngbTooltip]="'WALLET: '+walletAddressParam"
                 class="badge bg-white text-primary">Wallet {{walletAddressParam | walletShortener: 4}}</span>

  </h4>
  <p *ngIf="treesMetadatas.length" class="mx-auto text-center mb-0 fw-medium px-2">
    Total trees: {{treesMetadatas.length}} · Total CO<sub>2</sub> to be
    captured: {{ treesMetadatas.length * 700 > 1000 ? ((treesMetadatas.length * 0.7) | number: '1.2-4') + ' Tn' : (treesMetadatas.length * 700) + ' Kg' }}

  </p>
<!--  <h2 *ngIf="!treesMetadatas.length" class="mx-auto text-center">-->
<!--   Doesn't have any tree-->
<!--  </h2>-->
</ng-container>
<ng-container *ngIf="!walletAddressParam">
  <p *ngIf="treesMetadatas.length" class="mx-auto text-center mb-0 fw-medium px-2">
    Total trees: {{treesMetadatas.length}} · Total CO<sub>2</sub> to be
    captured: {{ treesMetadatas.length * 700 > 1000 ? ((treesMetadatas.length * 0.7) | number: '1.2-4')+ ' Tn' : (treesMetadatas.length * 700) + ' Kg' }}
  </p>
<!--  <h3 *ngIf="!treesMetadatas.length" class="mx-auto text-center">-->
<!--    You don't have any tree-->
<!--  </h3>-->
</ng-container>

<p class="text-center px-2">If you cant’t see your token please allow a few minutes for the transaction to be confirmed</p>

<div class="text-center">
  <a *ngIf="walletAddress || walletAddressParam" target="_blank" class="btn btn-secondary rounded-5 px-3"
     [href]="'https://niftyfair.io/gnosis/account/'+(walletAddressParam || walletAddress )+'/collected/'">
    View in marketplace
  </a>
  <button *ngIf="this.walletAddress" class="btn btn-dark rounded-5 ms-2 px-3 mt-2 mt-md-0" (click)="openWidgetModal()">
    Get Widget
  </button>
</div>
<div class="container text-center align-items-center h-100">
  <div *ngIf="treesMetadatas.length" class="row mt-3 justify-content-center">
    <div *ngFor="let nft of treesMetadatas" class="nft col-12 col-md-4 col-lg-3 my-3 mx-auto mx-md-0 card-group">
      <div class="card overflow-hidden border-white custom-border-1">
        <div class="border border-primary custom-border-2">
          <div class="border border-white custom-border-3 overflow-hidden">
            <img *ngIf="nft.image" src="{{nft.image}}" class="card-img-top pt-1"
                 (click)="walletAddressParam || selectToken($event, nft.id)"
                 type="button">
            <div class="card-body text-center px-0 pb-0">
              <div class="card-text">
                {{nft.name}}
                <br>
                <a
                  href="https://www.google.com/maps/search/?api=1&query={{forestsService.getMetadataCoordinates(nft)}}"
                  target="_blank"
                  class="text-decoration-none text-black">
                  <i class="fa-solid fa-location-dot me-1"></i>{{forestsService.getMetadataCoordinates(nft)}}
                </a>
              </div>
            </div>
            <div class="card-footer border-0 bg-transparent px-4 pb-0">
              <!--          <button class="btn btn-primary d-block w-100" (click)="openTransferModal(nft.id)" [disabled]="isTokenInArray(nft.id)">Transfer</button>-->
              <a [href]="'https://mytreeinitiative.com/uid/'+nft.id" target="_blank"
                 class="btn btn-primary d-block w-100 mb-2 rounded-5">Token ID: {{nft.id}}</a>
              <a href="/assets/documents/ISO.pdf" target="_blank"
                 class="btn btn-primary d-block w-100 mb-2 rounded-5">Certificate</a>
              <button *ngIf="!walletAddressParam" class="btn btn-primary d-block w-100 mb-2 rounded-5"
                      (click)="openTransferModal(nft.id)"
                      [disabled]="multipleTokens">
                Transfer
              </button>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

</div>

<!--<div *ngIf="!ethersService.loggedIn" class="d-flex">-->
<!--  <div class="col-10 col-lg-2 mx-auto mt-5 align-items-center">-->
<!--    <button class="btn btn-primary rounded-5 w-100 fs-4" disabled>-->
<!--        Log in-->
<!--    </button>-->
<!--  </div>-->
<!--</div>-->

<div *ngIf="loggedIn && loadingError" class="d-flex mt-3 p-4 rounded-3 text-center">
  <h1 class="mx-auto mt-5">ERROR CONNECTING TO SERVER. <br> PLEASE TRY IT AGAIN IN A FEW MINUTES</h1>
</div>

<div  class="div-transfer-multiple fixed-bottom d-flex" style="pointer-events: none;">
  <button class="btn rounded-5 ms-4 px-3 border-0" id="refresh-button" (click)="refresh()" [disabled]="refreshButtonDisabled"
          style="pointer-events: auto;">
    <i *ngIf="!refreshButtonDisabled" class="fa-solid fa-arrows-rotate"></i>
    <i *ngIf="refreshButtonDisabled" class="fa-solid fa-arrows-rotate fa-spin"></i>
  </button>
  <!--  <button class="btn btn-primary me-4 my-4">Transfer Multiple Tokens</button>-->
  <button *ngIf="multipleTokens" class="btn me-4 rounded-5 px-3 ms-auto" id="transfer-multiple-btn" (click)="openTransferModal()" type="button"
          style="pointer-events: auto;">
    <!--    <i class="fa-solid fa-share"></i>-->
    Transfer
  </button>
</div>
