<div class="modal-header">
  <h4 class="modal-title text-body">Export QR</h4>
  <button type="button" class="header-close-btn" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body d-flex justify-content-center">
  <qrcode *ngIf="qrExporterService.qrData" [elementType]="'img'" [qrdata]="qrExporterService.qrData" [errorCorrectionLevel]="'H'" [colorLight]="'#FFFDFD00'"></qrcode>
</div>
<div class="modal-footer justify-content-center">
  <button type="button" class="btn btn-dark" (click)="activeModal.close('Close click')">Close</button>
</div>
