import {Component, ViewChild} from '@angular/core';
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ImportMnemonicComponent} from "./import-mnemonic/import-mnemonic.component";
import {ImportPrivateKeyComponent} from "./import-private-key/import-private-key.component";
import {ImportKeystoreComponent} from "./import-keystore/import-keystore.component";
import {NewWalletComponent} from "./new-wallet/new-wallet.component";

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent {

  @ViewChild(ImportMnemonicComponent)
  public newWallet!: NewWalletComponent;

  @ViewChild(ImportMnemonicComponent)
  public importMnemonic!: ImportMnemonicComponent;

  @ViewChild(ImportPrivateKeyComponent)
  public importPrivateKey!: ImportPrivateKeyComponent;

  @ViewChild(ImportKeystoreComponent)
  public importWallet!: ImportKeystoreComponent;

  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
  ) {
  }

  openNewWalletModal() {
    //TODO: ADD NEW WALLET
    this.modalService.open(NewWalletComponent, {size: 'lg'});
    this.activeModal.close();
  }


  openImportKeystoreModal(){
    this.modalService.open(ImportKeystoreComponent,{size: 'lg'});
    this.activeModal.close();
  }


  openImportPrivateKeyModal(){
    this.modalService.open(ImportPrivateKeyComponent,{size: 'lg'});
    this.activeModal.close();
  }


  openImportMnemonicModal(){
    this.modalService.open(ImportMnemonicComponent,{size: 'lg'});
    this.activeModal.close();
  }

}
