<div id="connect-menu" class="modal-body" >
  <div class="p-3">
    <div class="zertiauth-header">
      <div>
        <div class="zertiauth-header-title">Log in</div>
        <div class="zertiauth-header-subtitle">Instant One-Click Blockchain Wallet</div>
      </div>
    </div>
    <button type="button" class="header-close-btn" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <!--<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <rect width="100%" height="100%" fill="transparent" />
        <path fill-rule="evenodd" clip-rule="evenodd" fill="black"
              d="M13.4142 12L19.7782 18.364L18.364 19.7782L12 13.4143L5.63604 19.7782L4.22183 18.364L10.5858 12L4.22183 5.63608L5.63604 4.22187L12 10.5858L18.364 4.22187L19.7782 5.63608L13.4142 12Z" />
      </svg>-->
      <span aria-hidden="true">&times;</span>
    </button>

    <button type="button" class="google-auth-btn d-flex justify-content-center align-items-center mt-3 w-100"
            title="Google" (click)="loginWithGoogle()">
      <img src="assets/img/connect/login-google-active.svg" height="auto" alt="login-google-active" class="hover-icon me-2">
      <img src="assets/img/connect/login-google-light.svg" height="auto" alt="login-google-light" class="image-icon me-2">
      <p class="text-white">
        Continue with Google
      </p>
    </button>

    <div class="row">
      <div class="col">
        <button type="button" class="auth-btn d-flex justify-content-center align-items-center mt-3 w-100"
                title="twitter" (click)="loginWithTwitter()">
          <img src="assets/img/connect/login-x-active.png" height="auto" alt="login-light" class="hover-icon">
          <img src="assets/img/connect/login-x-dark.png" height="auto" alt="login-active" class="image-icon">
        </button>
      </div>
      <div class="col">
        <button type="button" class="auth-btn d-flex justify-content-center align-items-center mt-3 w-100"
                title="facebook">
          <img src="assets/img/connect/login-facebook-active.svg" height="auto" alt="login-light" class="hover-icon">
          <img src="assets/img/connect/login-facebook-dark.svg" height="auto" alt="login-active" class="image-icon">
        </button>
      </div>
      <div class="col">
        <button type="button" class="auth-btn d-flex justify-content-center align-items-center mt-3 w-100"
                title="linked-in">
          <img src="assets/img/connect/login-linkedin-active.svg" height="auto" alt="login-light" class="hover-icon">
          <img src="assets/img/connect/login-linkedin-dark.svg" height="auto" alt="login-active" class="image-icon">
        </button>
      </div>
    </div>

    <div class="social_policy">
      We do not store any data related to your social login credentials.
    </div>
    <div class="zertiauth-modal-title mt-4 text-dark">
      External wallet
    </div>
    <button class="wallet-connect-btn w-100 mt-2 mb-4"  (click)="open(walletLogin)">
      Connect with wallet
    </button>
  </div>
</div>


<ng-template #walletLogin let-modal>
  <div id="connect-menu" class="modal-body px-4">
    <div class="zertiauth-header mb-2">
      <div class="pt-3">
        <div class="zertiauth-header-title text-dark">Authentication with Wallet</div>
        <div class="zertiauth-header-subtitle">Instant One-Click Blockchain Wallet</div>
      </div>
      <button type="button" class="header-close-btn" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <!--  <button (click)="metamaskConnect()" class="btn  btn-modal-connect  btn-block mb-3 text-left">Metamask <img
        class="float-right" src="assets/img/connect/MetaMask.png"></button>
      <button (click)="showWebWalletSignUp()"
              class="btn btn-modal-connect btn-block mb-3 text-left">{{'partials.connect.webWallet' | translate}} <i
        class="float-right fas fa-wallet"></i></button>-->
    <button class="wallet-connect-btn w-100 mt-3 mb-2 auth-btn d-flex justify-content-center align-items-center" (click)="loginWithMetamask()">
      <img src="assets/img/connect/metamask-active.png" height="auto" alt="login-light" class="hover-icon me-2">
      <img src="assets/img/connect/metamask-dark.png" height="auto" alt="login-active" class="image-icon me-2">
      Connect with Metamask
    </button>
    <button class="wallet-connect-btn w-100 mt-2 mb-4 d-none" (click)="loginWithWebWallet()">
      <i class="fa-solid fa-wallet me-2"></i> Connect with web wallet
    </button>
  </div>
</ng-template>
