import {Component, OnInit, ApplicationRef, ChangeDetectorRef} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {EthersService} from "../../../../services/ethers/ethers.service";
import {LoginComponent} from "../../login/login.component";

declare var $: any;

@Component({
  selector: 'app-import-private-key',
  templateUrl: './import-private-key.component.html',
  styleUrls: ['./import-private-key.component.scss']
})
export class ImportPrivateKeyComponent implements OnInit {
  privateKey!: string;
  privateKeyErr!: boolean;
  password!: string;
  repeatPassword!: string;

  passwordErr!: boolean;
  repeatPasswordErr!: boolean;
  passwordErrText!: string;

  constructor(
    public activeModal: NgbActiveModal,
    private ethersService: EthersService,
    private modalService: NgbModal,
  ) {
  }

  ngOnInit() {
    this.privateKey = '';
    this.privateKeyErr = false;
  }

  login() {
    if (this.verifyPassword()) {
      this.privateKeyErr = false;
      const wallet = this.ethersService.importWalletFromPrivateKey(this.privateKey, this.password);
      if (wallet) {
        this.activeModal.close();
      } else {
        this.privateKeyErr = true;
      }
    }
  }

  verifyPassword(): boolean {
    this.passwordErr = false;
    this.repeatPasswordErr = false;

    if (this.password !== this.repeatPassword) {
      // this.passwordErrText = errorRepeatPassword;
      this.passwordErrText = 'Passwords do not match.';
      this.passwordErr = true;
      this.repeatPasswordErr = true;

      return false;
    }
    if (!this.password) {
      // this.passwordErrText = errorEmptyPassword;
      this.passwordErrText = 'Type a password';
      this.passwordErr = true;
      return false;
    }
    this.passwordErrText = '';
    return true;

  }


}
