<!--<mgl-map id="map"
  [style]="'mapbox://styles/mapbox/outdoors-v9'"
  [zoom]="[2]"
  [center]="[27.218647224899613,61.52871513319946]"
  [attributionControl]="false"
>
</mgl-map>-->

<div id="map" class="overflow-hidden">

</div>
