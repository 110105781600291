import {Component, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {LoginComponent} from '../../login/login.component';
import {EthersService} from "../../../../services/ethers/ethers.service";

declare var $: any;

@Component({
  selector: 'app-import-keystore',
  templateUrl: './import-keystore.component.html',
  styleUrls: ['./import-keystore.component.scss']
})
export class ImportKeystoreComponent implements OnInit {
  file!: any;
  keystorePassword!: string;

  keystorePasswordErr!: boolean;
  fileErr!: boolean;
  importKeystoreErrText!: string;

  importBtn = {
    loading: false
  };

  constructor(
    public activeModal: NgbActiveModal,
    private ethersService: EthersService,
    private modalService: NgbModal,
  ) {
  }

  ngOnInit() {
    this.file = '';
    this.keystorePassword = '';
    this.fileErr = false;
    this.keystorePasswordErr = false;
    this.importKeystoreErrText = '';
  }


  onFileChange(e: any) {
    this.file = e.target.files[0];
  }

  login() {
    if (this.file && this.keystorePassword) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        this.importBtn.loading = true;
        const wallet = await this.ethersService.importKeystoreWallet(reader.result, this.keystorePassword);
        if (wallet) {
          this.activeModal.close();

          // this.modalService.open(LoginComponent);
        } else {
          this.keystorePasswordErr = true;
          this.importKeystoreErrText = 'Error: Wrong password. Please make sure the password is correct.';
        }
        this.importBtn.loading = false;
      };
      reader.readAsBinaryString(this.file);
    } else {
      this.keystorePasswordErr = true;
      this.fileErr = true;
      this.importKeystoreErrText = 'Error: You need to upload the keystore and write the password';
    }
  }

}
