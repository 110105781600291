import { Component } from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {PolicyModalComponent} from "./modals/policy-modal/policy-modal.component";
import {TermsModalComponent} from "./modals/terms-modal/terms-modal.component";
import {CookiesModalComponent} from "./modals/cookies-modal/cookies-modal.component";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  constructor(private modalService: NgbModal) {
  }

  openPolicyModal(){
    this.modalService.open(PolicyModalComponent, {fullscreen: true})
  }
  openTermsModal(){
    this.modalService.open(TermsModalComponent, {fullscreen: true})
  }
  openCookiesModal(){
    this.modalService.open(CookiesModalComponent, {fullscreen: true})
  }
}
