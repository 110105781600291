<footer class="w-100 py-3 footer mt-auto">
  <div class="container">
    <div class="text-center">
      <p>Powered by Zerticarbon and Zertifier
        <span class="mx-2 d-none d-block d-md-inline">·</span>
        <a href="http://www.mytreeinitiative.com/" target="_blanck" class="text-white text-decoration-none">
          www.mytreeinitiative.com
        </a>
        <span class="mx-2 d-none d-block d-md-inline">·</span>
        <a class="text-light text-decoration-none d-block d-md-inline footer-pointer" type="button"
           (click)="openPolicyModal()">
          Privacy policy
        </a>
        <span class="mx-2 d-none d-block d-md-inline">·</span>
        <a class="text-light text-decoration-none d-block d-md-inline footer-pointer" type="button"
           (click)="openTermsModal()">
          Terms &amp; conditions
        </a>
      </p>

    </div>
  </div>
</footer>


