import {Component, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {SignupComponent} from '../signup/signup.component';
import {EthersService} from "../../../services/ethers/ethers.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  password!: string;
  passwordErr!: boolean;

  constructor(
    public activeModal: NgbActiveModal,
    private ethersService: EthersService,
    public modalService: NgbModal,
  ) {
  }

  ngOnInit() {
  }

  clearWallet() {
    this.ethersService.clearKey();
    this.activeModal.close();
    this.modalService.open(SignupComponent, {size: 'lg', scrollable: true});

  }

  login() {
    try {
      this.ethersService.importWalletFromStorage(this.password);
      if (this.ethersService.wallet) {
        this.activeModal.close();
      }
    } catch (e) {
      this.passwordErr = true;
    }

  }
}
