<div class="bg-light">
  <div class="modal-header text-dark px-5">
    <h2 class="modal-title">Terms Policy</h2>
    <button type="button" class="btn-close shadow-none mx-4" aria-label="Close"
            (click)="activeModal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-text px-5 text-dark" style="background-color: #f1f1f1">

    <h4 class="c1">
      <span class="c3">
        Privacy statement of the customer and stakeholder register
      </span>
    </h4>
    <p class="c1">
      <span class="c0">
        Updated: 25/11/2023
      </span>
    </p>
    <p class="c1">
      <span class="c0">
        This statement describes how we process the personal data of our customers,
        suppliers and other stakeholders&#39; contact persons, as well as visitors to our website.
      </span>
    </p>
    <p class="mt-4">
      <span class="c5">
        1 Registrar
      </span>
    </p>
    <p class="c1">
      <span class="c0">
        Zerticarbon Oy (TAX ID 3246803-8)
      </span>
    </p>
    <p class="c1">
      <span class="c0">
        Address: Arinakatu, 3, 50170 Mikkeli (Finland)
      </span>
    </p>
    <p class="c1">
      <span class="c0">
        (hereinafter &quot;we&quot;)
      </span>
    </p>
    <p class="mt-4">
      <span class="c5">
        2 Contact information for registry matters
      </span>
    </p>
    <p class="c1">
      <span class="c0">
        Address: Arinakatu, 3, 50170 Mikkeli (Finland)
      </span>
    </p>
    <p class="c1">
      <span class="c20">Email:&nbsp;</span><span class="c20 c24">
        <a class="c23" href="mailto:info@zerticarbon.com">
        info@zerticarbon.com
        </a>
      </span>
    </p>
    <p class="c1">
      <span class="c0">
      All contacts and requests regarding this statement must be submitted in writing or in person to the contact point indicated above.
    </span>
    </p>
    <p class="mt-4">
      <span>
        3 Personal data to be processed, purpose of processing and legal basis
      </span>
    </p>
    <a id="t.429c24bca652b4a6664bbba5321a4eae481903c7"></a>
    <a id="t.0"></a>
    <table>
      <thead>
      <tr class="c7">
        <td class="c2" colspan="1" rowspan="1"><p class="c13 c18"><span class="c8">TYPES OF PERSONAL DATA</span></p>
        </td>
        <td class="c15" colspan="1" rowspan="1"><p class="c13"><span class="c8">PURPOSE OF PROCESSING</span></p></td>
        <td class="c6" colspan="1" rowspan="1"><p class="c13"><span class="c8">LEGAL BASIS</span></p></td>
      </tr>

      </thead>
      <tbody></tbody>

      <tr class="c7">
        <td class="c2" colspan="1" rowspan="1">
          <p class="c12 c21">
            <span class="c9"><b>Basic data of the registrant</b></span>
            <span class="c10">&nbsp;such as name, date of birth, customer number and language of business, land registry data<br><br></span>
            <span class="c9"><b>Registrant&#39;s contact data</b></span>
            <span class="c10">&nbsp;such as email address, phone number and address information<br><br></span>
            <span class="c9"><b>Information about the company and the company&#39;s contact persons</b></span>
            <span class="c17 c10">&nbsp;such as contact persons&#39; names, titles and contact information</span>
          </p>
        </td>
        <td class="c15" colspan="1" rowspan="1">
          <p class="c12">
            <span class="c17 c10">Delivering, organizing and developing our services<br><br>
              Fulfilling our contractual and other promises and obligations<br><br>Electronic direct marketing (including electronic surveys)<br><br>
              Managing our customer relations
            </span>
          </p>
        </td>
        <td class="c6" colspan="1" rowspan="1"><p class="c12">
          <span class="c17 c10">Legitimate interest<br><br>Contract enforcement<br><br>
            Consent (individuals) or legitimate interest (companies).<br><br>
            Legitimate interest
          </span>
        </p>
        </td>
      </tr>
      <tr class="c7">
        <td class="c2" colspan="1" rowspan="1">
          <p class="c12 c21">
            <span class="c9"><b>Consents and prohibitions regarding direct marketing</b></span>
          </p>
        </td>
        <td class="c15" colspan="1" rowspan="1"><p class="c12">
          <span class="c17 c10">Electronic direct marketing (including electronic surveys)</span>
        </p>
        </td>
        <td class="c6" colspan="1" rowspan="1">
          <p class="c12">
            <span class="c17 c10">Consent (individuals) or legitimate interest (companies).</span>
          </p>
        </td>
      </tr>
      <tr class="c7">
        <td class="c2" colspan="1" rowspan="1">
          <p class="c12 c21">
            <span class="c9"><b>Information related to customer relationships and contracts</b>,</span>
            <span class="c17 c10">&nbsp;such as information on past and current contracts and assignments, contact information, invoicing information</span>
          </p>
        </td>
        <td class="c15" colspan="1" rowspan="1">
          <p class="c12">
            <span class="c17 c10">Fulfilling our contractual and other promises<br><br>Managing and maintaining the customer relationship</span>
          </p>
        </td>
        <td class="c6" colspan="1" rowspan="1">
          <p class="c12">
            <span class="c10 c17">Contract enforcement<br><br>Legitimate interest</span>
          </p>
        </td>
      </tr>
      <tr class="c7">
        <td class="c2" colspan="1" rowspan="1">
          <p class="c12 c21">
            <span class="c9">
            <b>Information regarding the technical connection and the terminal device you use</b>,
            </span>
            <span class="c17 c10">
              &nbsp;such as IP address, device ID or other identifying information and cookies
            </span>
          </p>
        </td>
        <td class="c15" colspan="1" rowspan="1">
          <p class="c12">
            <span class="c17 c10">Behavioral analysis, profiling</span>
          </p>
        </td>
        <td class="c6" colspan="1" rowspan="1">
          <p class="c12">
            <span class="c17 c10">Consent</span>
          </p>
        </td>
      </tr>
    </table>
    <p class="mt-4">
      <span>
        4 Information sources
      </span>
    </p>
    <p class="c1">
      <span class="c0">
        We receive information primarily from the following sources: from the person himself, the population register, authorities,
        credit reference companies, contact information service providers and other similar trusted parties.
      </span>
    </p>
    <p class="c1">
      <span class="c0">
        In addition, personal data can be collected and updated for the purposes described in this privacy
        statement also based on information obtained from publicly available sources or other third parties within the limits of the applicable legislation.&nbsp;
        Such data updating is done manually or by automatic means.
      </span>
    </p>
    <p class="mt-4">
      <span >
        5 To whom we disclose information and whether we transfer information outside the EU or EEA
      </span>
    </p>
    <p>
      <span>
        We can hand over personal data within the limits allowed and required by current legislation to our associated companies
        participating in the provision of services and to legal and financial or other similar consultants who act as independent data controllers.
      </span>
    </p>
    <p>
      <span>
        In the processing of personal data, we use subcontractors working for us, whose personal data is stored on managed and protected servers.&nbsp;
        We use subcontractors in the following areas of our operations: marketing and communication, financial management and IT management.
      </span>
    </p>
    <p>
      <span>
        Due to the projects in the development phase, we cannot name each of our subcontractors,
        so we have ended up naming only the types of subcontractors.&nbsp;When we use subcontractors,
        we have ensured through contractual arrangements, among other things, that the subcontractor processes personal data only in accordance
        with our written instructions and only for the processing purposes specified in this statement.
      </span>
    </p>
    <p class="c1">
      <span class="c0">
        We process data primarily in the EU/EEA area.&nbsp;However, the IT management systems necessary to implement our services
        enable the transfer of data outside the EU/EEA area, such as to the United States.&nbsp;When personal data is processed outside the EU or EEA,
        we make sure that the subcontractor is committed to protective measures in accordance with
        the General Data Protection Regulation, such as the EU Commission&#39;s model clauses for the processing of personal data.
      </span>
    </p>
    <p class="mt-4">
      <span class="c5">
        6 Data protection
      </span>
    </p>
    <p class="c1">
      <span class="c0">
        Only those persons who have the right to process customer data due to their duties are entitled to use systems
        and databases containing personal data.&nbsp;Each user has their own username and password for the system.
        &nbsp;The information is collected in databases that are protected by firewalls, passwords and other technical means.
        &nbsp;The databases and their backups are located in locked rooms and only certain pre-designated persons can access the data.
      </span>
    </p>
    <p class="mt-4">
      <span class="c5">
        7 Retention period
      </span>
    </p>
    <p class="c1">
      <span class="c0">
        We store the personal data of customers and other stakeholders for the duration of the customership or contract,
        as well as the necessary claim and legal action period thereafter.&nbsp;We keep the data of potential customers for 5 years. &nbsp;
      </span>
    </p>
    <p class="c1">
      <span class="c0">
        We regularly assess the necessity of data retention, taking into account the applicable legislation.&nbsp;
        In addition to this, we take reasonable measures to ensure that no incompatible, out-of-date or incorrect
        personal data is stored in the register regarding the purposes of the processing.&nbsp;
        We will correct or delete such information without delay.
      </span>
    </p>
    <p class="mt-4">
      <span class="c5">
        8 Your rights as registered
      </span>
    </p>
    <p class="c1">
      <span class="c0">
        You have the right to check the information about you stored in the personal register&nbsp;
        and to demand the correction or deletion of incorrect, outdated, unnecessary or illegal information.&nbsp;
        If you yourself have access to your data, you can edit your data yourself.&nbsp;Insofar as the processing is based on consent,
        you also have the right to withdraw or change your consent at any time.&nbsp;Withdrawal of consent does not affect the legality of the processing
        that took place before the withdrawal of consent.
      </span>
    </p>
    <p class="c1">
      <span class="c0">
        You have the right to object to or request the restriction of the processing of your data and to file a complaint about the processing of personal data with
        the supervisory authority.
      </span>
    </p>
    <p class="c1">
      <span class="c0">
        For special personal reasons, you also have the right to object to processing actions directed at you when the basis for data processing is a legitimate interest.
        &nbsp;In connection with your claim, you must identify the specific situation based on which you object to the processing.
        &nbsp;We can refuse to implement a request for objection only on the basis of the law.
      </span>
    </p>
    <p class="c1">
      <span class="c0">
      To the extent that you yourself have submitted information to the customer register,
      which is processed automatically based on your consent or mandate, you have the right to receive such information for yourself in a machine-readable format
      as a rule and the right to transfer this information to another data controller.
      </span>
    </p>
    <h4 class="mt-4">
      Intellectual and industrial property
    </h4>
    <p class="c4">
      <span class="c0">
        This site&#39;s content, including the texts, images and designs belong to Zerticarbon, or to third parties that authorized it&#39;s use.
        Zerticarbon presents this contents with information and publicity purpose. It autorizes it&#39;s utilitzation exclusively for these purposes.
        It&#39;s use for commercial purpose or altering it is forbidden. Any use of these designs, images or texts will have to quote specifically the rightness
        of Zerticarbon, that keeps the right to initiate the appropriate legal actions to repare the damages caused because
        of any act that violate it&#39;s intellectual or industrial property rights.
      </span>
    </p>
    <p class="c4">
      <span class="c0">
        It is not authorized the presentation of contents from this site in an external window from Zerticarbon (framing). &nbsp;
      </span>
    </p>
    <h4 class="mt-4">
      Responsability on the content
    </h4>
    <p class="c4">
      <span class="c0">
        Even though Zerticarbon acts with the maximum of diligence possible, there&#39;s a chance that some
        date or information isn&#39;t completely updated when the website user consults it. Because of this,
        Zerticarbon states that the price references and services exposed in the website have an orientative function and do not
        oblige the site&#39;s principal until it&#39;s express confirmation.
      </span>
    </p>
    <p class="c4">
      <span class="c0">
        Zerticarbon keeps the right to modify unilaterally, at any moment and without prior notice, the presentation, configuration, and website content.
      </span>
    </p>
    <p class="c4">
      <span class="c0">
        Zerticarbon will not be responsible of the information obtained through links included in the website.
      </span>
    </p>
    <h4 class="mt-4">
      Conditions of use
    </h4>
    <p class="mb-0">
    <span class="c0">
      This website use implies the complete agreement of the terms and conditions of the present legal notice.
      The possible conflicts relative to this website will be regulated exclusively by the law of the Finland state, being the tribunal of Mikkeli the
      only competents. Every user from the website, independently from the territorial jurisdiction where it&#39;s
      access is produced, accepts the compliance and respect of these clauses with express abdication to any other regional code of laws.
    </span>
    </p>

  </div>
  <div class="modal-footer p-5">
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
  </div>
</div>

