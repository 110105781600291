<div class="container text-center align-items-center h-100">
  <div class="d-flex flex-column" *ngIf="!errorMint">
    <div *ngIf="areMinted" class="w-100">
      <div class="mt-3">
        <h1 *ngIf="treesMetadatas.length">
          You have successfully bought {{purchasedTrees.length}} trees
        </h1>
        <h1 *ngIf="!treesMetadatas.length">
          You don't have any tree
        </h1>
      </div>

      <div class="mt-4">
        <a class="btn btn-primary shadow" href="/home">
          Go home
        </a>
      </div>

      <div class="container text-center align-items-center h-100" *ngIf="areMinted && treesMetadatas.length">
        <div class="row mt-3 justify-content-center">
          <div *ngFor="let nft of treesMetadatas" class="nft col-12 col-md-4 col-lg-3 my-3 mx-auto mx-md-0 card-group">
            <div class="card overflow-hidden border-white custom-border-1">
              <div class="border border-primary custom-border-2">
                <div class="border border-white custom-border-3 overflow-hidden">
                  <img *ngIf="nft.image" src="{{nft.image}}" class="card-img-top p-1" (click)="selectToken($event, nft.id)"  type="button">
                  <div class="card-body text-center px-0">
                    <h5 class="card-title" (click)="loadTokenPage(nft.id)" type="button">{{nft.id}}</h5>
                    <div class="card-text">
                      {{nft.name}}
                      <br>
                      <a href="https://www.google.com/maps/search/?api=1&query={{forestService.getMetadataCoordinates(nft)}}" target="_blank"
                         class="text-decoration-none">
                        <i class="fa-solid fa-location-dot me-2"></i>{{forestService.getMetadataCoordinates(nft)}}
                      </a>
                    </div>
                  </div>
                  <div class="card-footer border-0 bg-transparent">
                    <!--              <button class="btn btn-primary d-block w-100" (click)="openTransferModal(nft.id)" [disabled]="isTokenInArray(nft.id)">Transfer</button>-->
                    <a href="/assets/documents/ISO.pdf" target="_blank" class="btn btn-dark d-block w-100 mb-2">Certificate</a>
                    <button class="btn btn-primary d-block w-100" (click)="openTransferModal(nft.id)" [disabled]="multipleTokens">Transfer</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="!areMinted" class="mt-5">
      <h1>
        Payment received
      </h1>
      <h1 class="my-4">
        Generating NFTs, please wait...
      </h1>

      <span class="fs-1"><i class="fa-solid fa-spinner fa-spin"></i></span>

    </div>
  </div>

  <div *ngIf="errorMint">
    <h1 class="my-4">
      We are busy, your NFTs will be generated in a while.
    </h1>
  </div>
</div>

<div *ngIf="multipleTokens" class="text-end div-transfer-multiple fixed-bottom">
  <!--  <button class="btn btn-primary me-4 my-4">Transfer Multiple Tokens</button>-->
  <button class="btn me-4 rounded-5 px-3" id="transfer-multiple-btn" (click)="openTransferModal()">
    <!--    <i class="fa-solid fa-share"></i>-->
    Transfer
  </button>
</div>
