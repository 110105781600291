<div class="modal-header">
  <h4 class="modal-title text-body text-center">Import Keystore</h4>
  <button type="button" class="header-close-btn" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body text-dark">
  <input (change)="onFileChange($event)" [ngClass]="{'is-invalid': fileErr}" type="file" class="form-control mb-3">
  <app-show-hide-input-text>
    <input [(ngModel)]="keystorePassword" [ngClass]="{'is-invalid': keystorePasswordErr}" type="password"
           class="form-control"
           [placeholder]="'Keystore password'">
  </app-show-hide-input-text>
  <div *ngIf="importKeystoreErrText" [innerHTML]="importKeystoreErrText" class="invalid mb-2 text-danger">
  </div>
</div>
<div class="modal-footer justify-content-center">
  <app-spinner-button (click)="login()" [options]="importBtn">
    Login
  </app-spinner-button>
</div>

