<div class="container mt-5">
  <div class="row flex-column">
    <div class="col-12 col-lg-5 bg-dark bg-opacity-75 px-md-5 mx-auto rounded-3 py-4">
      <div class="col-12 mb-3 mx-auto">
        <h4 class="text-center my-3">Wallet Address</h4>
        <input [ngModel]="walletAddress" (click)="copyToClipboard(walletAddress, tooltipWallet)"
               #tooltipWallet="ngbTooltip"
               ngbTooltip="Copied!"
               class="form-control text-center bg-white cursor-pointer"
               type="button" readonly>
      </div>
      <div *ngIf="!isMetamask" class="col-12 mt-3 mb-4 mx-auto">
        <h4 class="text-center my-3">Private Key</h4>
        <div class="input-group">
          <input [ngModel]="privateKey" type="{{ isPasswordVisible ? 'button' : 'password' }}"
                 (click)="copyToClipboard(privateKey, tooltipPrivate)"
                 #tooltipPrivate="ngbTooltip"
                 ngbTooltip="Copied!"
                 class="form-control text-center bg-white shadow-none cursor-pointer" readonly>
          <button (click)="togglePasswordVisibility()" class="input-group-text bg-white">
            <i *ngIf="!isPasswordVisible" class="fa-solid fa-eye"></i>
            <i *ngIf="isPasswordVisible" class="fa-solid fa-eye-slash"></i>
          </button>
        </div>
      </div>
    </div>

  </div>
</div>
