import {AfterViewInit, Component} from '@angular/core';
import {Web3Auth} from "@web3auth/modal";
import {EthersService} from "../../services/ethers/ethers.service";
import {Forest} from "../../shared/interfaces/trees-interface";
import {ForestsService} from "../../services/forests/forests.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ConnectModalComponent} from "../../views/partials/connect-modal/connect-modal.component";
import {ZertiAuthApiService} from "../../services/zerti-auth/zerti-auth-api.service";
import {ActivatedRoute} from "@angular/router";
import {PrivateKeyHttpResponse} from "../../shared/interfaces/zertiauth-http";
import {ethers} from "ethers";
import {LoginComponent} from "../../views/partials/login/login.component";


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements AfterViewInit {
  web3auth: Web3Auth | null = null;
  loggedIn = false;
  isModalLoaded = false;
  walletAddress: string = this.ethersService.walletAddress || ''
  selectedForest!: Forest | null

  constructor(
    public ethersService: EthersService,
    private forestsService: ForestsService,
    public modalService: NgbModal,
    public zertiAuthServ: ZertiAuthApiService,
    private route: ActivatedRoute,
  ) {
    this.forestsService.$selectedForest.subscribe((forest: Forest | null) => {
      this.selectedForest = forest
    })
    this.ethersService.$loggedIn.subscribe((loggedIn: boolean) => {

      this.loggedIn = loggedIn
    })

    this.ethersService.$walletAddress.subscribe((walletAddress: string) => {
      if (walletAddress){
        this.walletAddress = walletAddress
        this.loggedIn = true
      }

    })
  }

  async ngAfterViewInit() {
    if (!localStorage.getItem('pkBody')) {
      this.loadWallet()
    }

  }

  login = async () => {
    const modalRef = this.modalService.open(ConnectModalComponent, {scrollable: true});

  };

  loadWallet() {
   /* if (this.ethersService.walletAddress) {
      this.walletAddress = this.ethersService.walletAddress
    } else {*/
      if (localStorage.getItem('connectionInfo') &&
        JSON.parse(localStorage.getItem('connectionInfo')!).connectMethod == 'WEBWALLET' &&
        !this.ethersService.wallet
      ) {
        this.modalService.open(LoginComponent, {scrollable: true});

      } else {
        this.route.queryParams.subscribe(params => {
          const code = params['code'];
          if (code) {
            this.zertiAuthServ.getPrivateKey(code).subscribe({
              next: (res: PrivateKeyHttpResponse) => {
                // localStorage.removeItem('baseCodeChallenge');
                const privateKey = res.data.privateKey;
                this.ethersService.connectMethod = 'ZERTIAUTH'
                this.ethersService.createWalletFromPrivateKey(privateKey) //THIS IS YOUR WALLET
              },
              error: (error) => {
                localStorage.removeItem('baseCodeChallenge');
                this.loggedIn = false

                console.log(error)
              }
            })
          }
        });
      }

    // }

  }

  changeNetwork(chainId: string) {
    this.ethersService.changeNetwork(chainId)
  }

  logout = async () => {
    this.ethersService.logout()
    this.loggedIn = false;
  };
}
