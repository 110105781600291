import {Component, OnDestroy} from '@angular/core';
import {CountryTrees, Forest} from "../../../shared/interfaces/trees-interface";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {BuyModalComponent} from "./modals/buy-modal/buy-modal.component";
import {ForestsService} from "../../../services/forests/forests.service";
import {Subscription} from "rxjs";
import {Router} from "@angular/router";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnDestroy{


  forests: Forest[] = [];

  selectedCountry: string = ''
  availableForests: any[] = []

  private subscriptions: Subscription = new Subscription();

  constructor(private modalService: NgbModal, private forestService: ForestsService, private router: Router) {
    this.forests = this.forestService.forests
    this.subscriptions.add(
      this.forestService.$forests.subscribe((forests: Forest[]) => {
        this.forests = forests
        this.getSelectedCountry('Finland')
      })
    )
  }
  ngOnInit(){

    this.getSelectedCountry('Finland')

  }



  getImage(treeType: string){
    switch (treeType.toUpperCase()){
      case 'OAKS': return '/assets/img/1.png';
      case 'PINE': return '/assets/img/1.png';
      case 'BIRCH': return '/assets/img/2.png';
      case 'COTTONWOOD': return '/assets/img/2.png';
      default: return '/assets/img/2.png'
    }
  }

  getSelectedCountry(country: string){
    this.selectedCountry = country
    this.availableForests = this.forests.filter((forest: Forest) => forest!.country.toUpperCase() == this.selectedCountry.toUpperCase())
  }

  openBuyModal(forest: any){
    const modalRef = this.modalService.open(BuyModalComponent, {fullscreen: true});
    forest.year = (parseFloat(forest.year) * 1000).toString(); //parse the year to milliseconds
    modalRef.componentInstance.wood = forest;
  }

  openPurchase(forestId: number){
    this.router.navigate([`/purchase/${forestId}`]);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
