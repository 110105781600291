import {Component, Input} from '@angular/core';
import {NgbActiveModal, NgbModal, NgbTooltip} from "@ng-bootstrap/ng-bootstrap";
import {Clipboard} from "@angular/cdk/clipboard";

@Component({
  selector: 'app-widget-modal',
  templateUrl: './widget-modal.component.html',
  styleUrls: ['./widget-modal.component.scss']
})
export class WidgetModalComponent {
  constructor(
    public activeModal: NgbActiveModal,
    public modalService: NgbModal,
    private clipboard: Clipboard,
  ) {
  }

  @Input() walletAddress: string = ''
  IFrame: string = ''


  createIFrameElement(){
    this.IFrame =
      `<iframe src="https://mytreeinitiative.com/certificate.php?wallet=${this.walletAddress}" style="width: 100%; height: 400px; border: 0">Browser not compatible..</iframe>`

    return this.IFrame
  }

  copyToClipboard(value: string, tooltip: NgbTooltip) {
    tooltip.open()
    this.clipboard.copy(value);

    setTimeout(() => {
      tooltip.close();
    }, 1500);

  }
}
