import {Component, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {LoginComponent} from '../../login/login.component';
import {EthersService} from "../../../../services/ethers/ethers.service";


@Component({
  selector: 'app-new-wallet',
  templateUrl: './new-wallet.component.html',
  styleUrls: ['./new-wallet.component.scss']
})
export class NewWalletComponent implements OnInit {
  step!: string;
  wallet!: any;
  password!: string;
  repeatPassword!: string;
  passwordErr!: boolean;
  repeatPasswordErr!: boolean;
  passwordErrText!: string;

  constructor(
    private ethersService: EthersService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,

  ) {
  }

  ngOnInit() {
    this.password = '';
    this.repeatPassword = '';
    this.step = 'newPassword';
  }

  createWallet() {
    if (this.verifyPassword()) {
      this.wallet = this.ethersService.newWallet(this.password);
      this.step = 'walletInfo';
    }
  }

  verifyPassword(): boolean {
    this.passwordErr = false;
    this.repeatPasswordErr = false;
    if (this.password !== this.repeatPassword) {

        this.passwordErrText = 'Passwords do not match.';
        this.passwordErr = true;
        this.repeatPasswordErr = true;
      return false;
    }
    if (!this.password) {

        this.passwordErrText = 'Type a password';
        this.passwordErr = true;
      return false;
    }
    this.passwordErrText = '';
    return true;
  }

  login() {
    this.modalService.open(LoginComponent);
    //this.authService.login();
  }

}
