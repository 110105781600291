import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'walletShortener'
})
export class WalletShortenerPipe implements PipeTransform {

  transform(wallet_address: string, length: number): string {
    return `${wallet_address.slice(0, length+2)}...${wallet_address.slice(wallet_address.length - length, wallet_address.length)}`
  }
}
