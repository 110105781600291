import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HeaderComponent} from './layouts/header/header.component';
import {HomeComponent} from './views/pages/home/home.component';
import {NgbDropdownModule, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {WalletShortenerPipe} from "./shared/pipes/wallet-shortener.pipe";
import {MapComponent} from './shared/components/map/map.component';
import {BuyModalComponent} from "./views/pages/home/modals/buy-modal/buy-modal.component";
import {FooterComponent} from './layouts/footer/footer.component';
import {FormsModule} from "@angular/forms";
import {DatePipe} from "@angular/common";
import { PurchaseTreesComponent } from './views/pages/purchase-trees/purchase-trees.component';
import { StripeModalComponent } from './views/pages/purchase-trees/stripe-modal/stripe-modal.component';
import { HttpClientModule} from "@angular/common/http";
import { PurchaseSuccessComponent } from './views/pages/purchase-success/purchase-success.component';
import { PolicyModalComponent } from './layouts/footer/modals/policy-modal/policy-modal.component';
import { TermsModalComponent } from './layouts/footer/modals/terms-modal/terms-modal.component';
import { CookiesModalComponent } from './layouts/footer/modals/cookies-modal/cookies-modal.component';
import { ConnectModalComponent } from './views/partials/connect-modal/connect-modal.component';
import { SignupComponent } from './views/partials/signup/signup.component';
import { ImportMnemonicComponent } from './views/partials/signup/import-mnemonic/import-mnemonic.component';
import { ShowHideInputTextComponent } from './shared/components/show-hide-input-text/show-hide-input-text.component';
import { QrImporterModalComponent } from './shared/components/qr-importer-modal/qr-importer-modal.component';
import { QrExporterModalComponent } from './shared/components/qr-exporter-modal/qr-exporter-modal.component';
import {QRCodeModule} from "angularx-qrcode";
import { ImportPrivateKeyComponent } from './views/partials/signup/import-private-key/import-private-key.component';
import { LoginComponent } from './views/partials/login/login.component';
import { ImportKeystoreComponent } from './views/partials/signup/import-keystore/import-keystore.component';
import { SpinnerButtonComponent } from './shared/components/spinner-button/spinner-button.component';
import { NewWalletComponent } from './views/partials/signup/new-wallet/new-wallet.component';
import { WalletInfoComponent } from './views/partials/wallet-info/wallet-info.component';
import { DownloadKeystoreComponent } from './views/partials/download-keystore/download-keystore.component';
import { TotalTreesComponent } from './views/pages/total-trees/total-trees.component';
import { MywalletComponent } from './views/pages/mywallet/mywallet.component';
import {ClipboardModule} from "@angular/cdk/clipboard";
import { TransferModalComponent } from './views/partials/transfer-modal/transfer-modal.component';
import { PurchaseErrorComponent } from './views/pages/purchase-error/purchase-error.component';
import { WidgetModalComponent } from './views/pages/total-trees/modals/widget-modal/widget-modal.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    WalletShortenerPipe,
    MapComponent,
    BuyModalComponent,
    FooterComponent,
    PurchaseTreesComponent,
    StripeModalComponent,
    PurchaseSuccessComponent,
    PolicyModalComponent,
    TermsModalComponent,
    CookiesModalComponent,
    ConnectModalComponent,
    SignupComponent,
    ImportMnemonicComponent,
    ShowHideInputTextComponent,
    QrImporterModalComponent,
    QrExporterModalComponent,
    ImportPrivateKeyComponent,
    LoginComponent,
    ImportKeystoreComponent,
    SpinnerButtonComponent,
    NewWalletComponent,
    WalletInfoComponent,
    DownloadKeystoreComponent,
    TotalTreesComponent,
    MywalletComponent,
    TransferModalComponent,
    PurchaseErrorComponent,
    WidgetModalComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    NgbDropdownModule,
    FormsModule,
    HttpClientModule,
    QRCodeModule,
    ClipboardModule
  ],
  providers: [DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule {
}
