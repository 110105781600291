<div class="mt-3">
  <div class="text-center country-div">
    <img class="mx-2" src="assets/img/finland-map.png">
    <img class="mx-2" src="assets/img/finland-flag.png">
  </div>
  <div class="container-md my-3" *ngIf="availableForests.length > 0">
    <h4 class="text-center header-subtitle fs-4 my-4"> {{selectedCountry}}</h4>
    <table class="table table-striped d-none d-md-table">
      <thead>
        <tr class="text-center bg-secondary">
          <td class="col-2 border-2 border-white">Forest</td>
          <td class="col-2 border-2 border-white">Total trees</td>
          <td class="col-2 border-2 border-white">Available trees</td>
          <td class="col-2 border-2 border-white"></td>
        </tr>
      </thead>
      <tbody>
      <tr *ngFor="let forest of availableForests" class="text-center">
        <td class="border-2 border-white py-2">{{forest.name}}</td>
        <td class="border-2 border-white py-2">{{forest.qty}}</td>
        <td class="border-2 border-white py-2">{{forest.qty - forest.used}}</td>
        <td class="border-2 border-white py-2">
<!--          <button class="btn btn-primary bg-transparent shadow-sm border-white fs-5" (click)="openBuyModal(forest)">Purchase trees</button>-->
          <button class="btn btn-primary bg-transparent shadow-sm border-white" id="forest-{{forest.id}}"(click)="openPurchase(forest.id)">Purchase trees</button>
        </td>
      </tr>
      </tbody>
    </table>

    <div class="d-block d-md-none">
      <ul *ngFor="let forest of availableForests" class="list-group list-group-horizontal my-2 shadow "
          type="button" (click)="openPurchase(forest.id)">
        <li class="list-group-item flex-fill bg-primary border-2 border-end-0 border-white">
          <div class="pt-2">
            <div class="d-flex w-100">
              <h5 class="text-white">
                {{forest.name}}
              </h5>
            </div>
            <p class="mb-1 text-white">
              Total trees: {{forest.qty}}
            </p>
            <p class="mb-1 text-white">
              Available trees: {{forest.qty - forest.used}}
            </p>
          </div>
        </li>
        <li class="d-flex flex-column align-center pt-4 bg-primary rounded-2 rounded-start-0 border-start-0 text-right" style="border: solid white 2px ">
            <button class="btn btn-form-table shadow-none pe-4 pt-3" ngbTooltip="Options" style="font-size: 22px">
              <i class="fas fa-arrow-right text-white"></i>
            </button>
        </li>

      </ul>
    </div>
  </div>
</div>

